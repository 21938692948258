import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import WorkIcon from '@mui/icons-material/Work';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[500],
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px 16px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px',
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
  },
}));


const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px',
  gap: '16px',
  maxWidth: '1250px',
  margin: '0 auto',
});


const FormButton = styled(Button)({
  width: '100%',
  backgroundColor: 'blue',
  color: 'white',
  '&:hover': {
    backgroundColor: 'darkblue',
  },
});

const FormField = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
});

const FormIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const FormInput = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
});

function FormMui() {
  const [formData, setFormData] = useState({
    name_of_entry: '',P_and_s_offered: '',
    other:'',
    name: '',
    designation: '',
    company: '',
    phone: '',
    email: '',
    location: '',
  });
  const [loanType, setLoanType] = useState('');

  const handleChangewhite = (event) => {
    setLoanType(event.target.value);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    // console.log("typr ",name,value)
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission, e.g., send data to a server
    console.log('Form submitted:', formData);
  };

  return (
    <FormContainer className='form_first'>
      <form onSubmit={handleSubmit}>
        {/* <p>For Partner with us (Banks & Financial institutions)</p> */}
        <FormField>
          {/* <FormIcon>
            <PersonIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="name_of_entry"
              name="name_of_entry"
              label="Name of Entity :"
              variant="outlined"
              fullWidth={true}
              value={formData.name_of_entry}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <PersonIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="P_and_s_offered"
              name="P_and_s_offered"
              label="Products & Services offered:"
              variant="outlined"
              fullWidth={true}
              value={formData.P_and_s_offered}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        {/* <p><b>What are you looking for?</b></p> */}

        {/* <Box sx={{ minWidth: 120 }}> */}
      <FormControl fullWidth className='Select_data_form'>
        <InputLabel id="demo-simple-select-label">What are you looking for?</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={loanType}
          // label="What are you looking for?"
          onChange={handleChangewhite}
        > 
          <MenuItem value="Colending" className='select_data'>Colending</MenuItem>
          <MenuItem value="Term loans"  className='select_data'>Term loans for NBFC & Institutions</MenuItem>
          <MenuItem value="Business Correspondence Channel"  className='select_data'>Business Correspondence Channel</MenuItem>
          <MenuItem value="Loan origination Channel"  className='select_data'>Loan origination Channel</MenuItem>
          <MenuItem value="Corporate Debt/Equity Restructure"  className='select_data'>Corporate Debt/Equity Restructure</MenuItem>
        </Select>
      </FormControl>
    {/* </Box> */}



        {/* <p className='ul_class_li'>
        <ul>
          <li>Colending</li>
          <li>Term loans for NBFC & Institutions</li>
          <li>Business Correspondence Channel </li>
          <li>Loan origination Channel</li>
          <li>Corporate Debt/ Equity Restructure</li>
        </ul>
        </p> */}
        {/* <div className='Select_data_form' style={{margin:"auto"}}>
      <FormControl fullWidth>
        <InputLabel id="loan-type-label">Select Loan Type</InputLabel>
        <Select
          labelId="loan-type-label"
          id="loan-type"
          value={loanType}
          label="What are you looking for?"
          onChange={handleChangewhite}
        >

          <MenuItem value="Colending">Colending</MenuItem>
          <MenuItem value="Term loans">Term loans for NBFC & Institutions</MenuItem>
          <MenuItem value="Business Correspondence Channel">Business Correspondence Channel</MenuItem>
          <MenuItem value="Loan origination Channel">Loan origination Channel</MenuItem>
          <MenuItem value="Corporate Debt/Equity Restructure">Corporate Debt/Equity Restructure</MenuItem>
        </Select>
      </FormControl>
    </div> */}
          <FormField>
          {/* <FormIcon>
            <PersonIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="other"
              name="other"
              label="Others:"
              variant="outlined"
              fullWidth={true}
              value={formData.others_ti}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <PersonIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="name"
              name="name"
              label="Name:"
              variant="outlined"
              fullWidth={true}
              value={formData.name}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <WorkIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="designation"
              name="designation"
              label="Designation:"
              variant="outlined"
              fullWidth={true}
              value={formData.designation}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <BusinessCenterIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="company"
              name="company"
              label="Company:"
              variant="outlined"
              fullWidth={true}
              value={formData.company}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField className='phone_div'>
          {/* <FormIcon>
            <PhoneIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="phone"
              name="phone"
              label="Phone:"
              variant="outlined"
              fullWidth={true}
              value={formData.phone}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField  className='email_div'>
          {/* <FormIcon>
            <EmailIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="email"
              name="email"
              label="Email:"
              variant="outlined"
              fullWidth={true}
              value={formData.email}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <LocationOnIcon />
          </FormIcon> */}
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="location"
              name="location"
              label="Location:"
              variant="outlined"
              fullWidth={true}
              value={formData.location}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>
        <div className='btn_container'>
        <FormButton className='verify_btn' type="submit" variant="contained">
          Submit
        </FormButton>
        </div>
      </form>
    </FormContainer>
  );
}

export default FormMui;
