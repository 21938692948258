import React from 'react'
import Embeddedwithimage from '../components/Embeddedwithimage'
import EmbeddedLandingSolutions from '../components/EmbeddedLandingSolutions'
import ExploreApi from '../components/ExploreApi'
import PartnerOurSay from '../components/PartnerOurSay'
import PartnersLogo from '../components/PartnersLogo'
import PartnerWithUs from '../components/PartnerWithUs'

export default function EmbeddedFinance() {
  return (
    <div>
        <Embeddedwithimage/>
        <EmbeddedLandingSolutions/>
        <ExploreApi/>
        <PartnerOurSay/>
        <PartnersLogo title={"OUR LENDING PARTNERS"}/>
        <PartnerWithUs/>
    </div>
  )
}
