import React, { useEffect, useState } from 'react'
import logo_img from "../assets/images/Logo.png"
import TemporaryDrawer from './mobile-menu'

export default function Header({ className, pathweb }) {
    const [tickerValue, setTickerValue] = useState("Loading...");
    const [marketValues, setMarketValues] = useState("Loading...");
  
    useEffect(() => {
      const fetchStockData = async () => {
        try {
            const response = await fetch("https://worker-rough-sunset-d2d7.niyogincf.workers.dev", {
               method: 'GET',
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "application/json",
                },
              },
            ); 

          const data = await response.json();
          setTickerValue(data.stockData);
          setMarketValues(data.stockmarketCapitalValue);
  
          const timestamp = new Date().getTime();
          localStorage.setItem("tickerValue", data.stockData);
          localStorage.setItem("marketValues", data.stockmarketCapitalValue);
          localStorage.setItem("stockDataTimestamp", timestamp);
        } catch (error) {
          console.error("Error fetching stock data:", error);
        }
      }; 
  
      const checkAndFetchData = () => {
        if (typeof localStorage !== "undefined") {
          const storedTickerValue = localStorage.getItem("tickerValue");
          const storedMarketValues = localStorage.getItem("marketValues");
          const storedTimestamp = localStorage.getItem("stockDataTimestamp");
  
          const now = new Date().getTime();
          const isExpired = !storedTimestamp || now - storedTimestamp > 20 * 60 * 1000;
  
          if (storedTickerValue && storedMarketValues && !isExpired) {
            setTickerValue(storedTickerValue);
            setMarketValues(storedMarketValues);
          } else {
            fetchStockData();
          }
        }
      };
  
      checkAndFetchData();
    }, []);

    

    const MenuClicked = (e) =>{
        console.log(" ----", e.target.classList.contains("li_main"))
        if(e.target.classList.contains("li_main")){
           e.target.style.color = "rgb(36, 160, 208)"
           e.target.classList.add("active_this")
       }

    }

    
    const MenuRemoveClicked = (e) =>{
        console.log(" ----", e.target.classList.contains("li_main"))
        if(e.target.classList.contains("li_main")){
        //    e.target.style.color = "rgb(36, 160, 208)"
           e.target.classList.remove("active_this")
       }

    }

    const MenuClicked2= (e) =>{
        console.log(" ----",e.target)
        if(e.target.classList.contains("level_three_menu")){
            e.target.classList.toggle("active_this_li")
        }        
    }
    
  return (
    <>
   <div style={{ backgroundColor: "#ffffff", color: "#000000", fontSize: "14px", padding: "4px", whiteSpace: "nowrap" }}>
        <marquee scrollamount="5">
          {`Niyogin Fintech Ltd : ${tickerValue || 73.55 }Inr Mkt Cap: ${marketValues || 703.59}`}
        </marquee>
      </div>
    <div className={className + " header_main_cont"} >
        <div className='header_main_cont_div'>
            <div className='header_main'>
                <div class="logo_cont">
                    <a href='/'>
                        <img src={logo_img} alt="niyogin logo"/>
                    </a>
                </div>

                <div className='menu_cont'>
                    <ul>
                        <li><a style={{color:"/about-us" === pathweb ?"#24A0D0" :"white"}} href="/about-us">About us</a></li>
                        <li className='li_main' onMouseLeave={(e)=>{MenuRemoveClicked(e)}}  onMouseEnter ={(e)=>{MenuClicked(e)}} onClick ={(e)=>{MenuClicked(e)}}>
                            <a style={{color:"/Solutions" === pathweb ?"#24A0D0" :"white",pointerEvents:"none"}} href="#">Solutions</a>
                            <ul className='level_two_menu'>
                                <li><a href="/Embedded-Finance">Embedded Lending</a></li>
                                <li><a href="/distribution-network">Distribution Network</a></li>
                                <li className='level_three_menu'onMouseEnter={(e)=>{MenuClicked2(e)}}  onClick={(e)=>{MenuClicked2(e)}}>
                                    <a style={{pointerEvents:"none"}}>Subsidiaries </a>
                                    <ul className='level_4_menu'>
                                        <li><a href="https://iserveu.in/">iServeU</a></li>
                                        <li><a href="https://moneyfront.in/">Money Front</a></li>
                                        <li ><a href="https://niyogin.ai/">Niyogin AI </a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><a style={{color:"/Investor-Relations" === pathweb ?"#24A0D0" :"white"}} href="Investor-Relations">Investor Relations</a></li>
                        <li><a style={{color:"/media" === pathweb ?"#24A0D0" :"white"}} href="/news-room">Media</a></li>
                        <li><a style={{color:"/blogs" === pathweb ?"#24A0D0" :"white"}} href="/blogs">Blogs</a></li>
                    </ul>
                </div>

                <div className='header_btn'>
  <ul>
    <li><a href="/distribution-network#collabra_opp">Partner Registration</a></li>
    <li><a href="https://niyoblu.com/auth/login">NiyoBlu</a></li>
    {window.location.href.includes('niyoblu.com') && (
      <li><a href="https://www.niyogin.com/login">Niyogin</a></li>
    )}
  </ul>
</div>


                <div className="mobile_menu_div">
                    <TemporaryDrawer pathweb={pathweb}/>
                </div>
            </div> 
        </div>
    </div>
  </>
  )
}