import React from "react";
import ContactUsImage from "../components/ContactUsImage";
import ContactInfo from "../components/ContactInfo";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
  return (
    <div>
      <ContactUsImage />
      <ContactInfo />
      <ContactForm/>
    </div>
  );
};

export default ContactUs;
