import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import mob_log from '../assets/images/Logo.png';
import Close_icon from '../assets/images/Menu_close.svg';

export default function TemporaryDrawer({ pathweb }) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const menuLinks = [
    { label: 'About us', path: '/about-us' },
    { label: 'Solutions', path: null }, // Accordion will handle this
    { label: 'Investor Relations', path: '/Investor-Relations' },
    { label: 'Media', path: '/news-room' },
    { label: 'Blogs', path: '/blogs' },
  ];

  const additionalLinks = [
    { label: 'Partner Registration', path: '/distribution-network#collabra_opp' },
    { label: 'NiyoBlu', path: 'https://niyoblu.com/auth/login' },
    ...(window.location.href.includes('niyoblu.com') ? [{ label: 'Niyogin', path: 'https://www.niyogin.com/login' }] : [])
  ];
  
  if (window.location.href.includes('niyogin.com/login')) {
    window.location.href = 'https://niyoblu.com/auth/login';
  }
  
  {additionalLinks.map((link, index) => (
    <li key={index}>
      <a href={link.path}>{link.label}</a>
    </li>
  ))}
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleNavigation = (path) => {
    if (path) {
      if (path.startsWith('http')) {
        window.open(path, '_blank', 'noopener,noreferrer');
      } else {
        navigate(path);
      }
      setOpen(false);
    }
  };

  const DrawerList = (
    <Box sx={{ width: '100%', textAlign: 'center' }} role="presentation">
      <div className="logo_and_cls">
        <div className="logo_btn_img">
          <a href="/">
            <img src={mob_log} alt="Niyogin Logo" />
          </a>
        </div>
        <div className="cls_btn_img" onClick={toggleDrawer(false)}>
          <img src={Close_icon} alt="Close Button" />
        </div>
      </div>

      <List className="header_menu_div">
        {menuLinks.map((item, index) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton>
              {item.label === 'Solutions' ? (
                <Accordion className="tab_of_mobile_menu">
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                    className="head_mob_1"
                  >
                    {item.label}
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul className="level_two_menu">
                      <li>
                        <Link to="/Embedded-Finance" onClick={toggleDrawer(false)}>
                          Embedded Lending
                        </Link>
                      </li>
                      <li>
                        <Link to="/distribution-network" onClick={toggleDrawer(false)}>
                          Distribution Network
                        </Link>
                      </li>
                      <li>
                        <Accordion className="tab_of_mobile_menu">
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`sub-panel${index}-content`}
                            id={`sub-panel${index}-header`}
                            className="mobile_last_tab"
                          >
                            Subsidiaries
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul className="level_4_menu">
                              <li>
                                <a href="https://iserveu.in/" target="_blank" rel="noopener noreferrer">
                                  iServeu
                                </a>
                              </li>
                              <li>
                                <a href="https://moneyfront.in/" target="_blank" rel="noopener noreferrer">
                                  Money Front
                                </a>
                              </li>
                              <li>
                                <a href="https://niyogin.ai/" target="_blank" rel="noopener noreferrer">
                                  Niyogin AI
                                </a>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      </li>
                    </ul>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <ListItemText
                  primary={item.label}
                  className={`head_mob_1 ${item.path === pathweb ? 'active_a' : ''}`}
                  onClick={() => handleNavigation(item.path)}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>

      <Divider />

      <List className="header_menu_div">
        {additionalLinks.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton>
              <ListItemText
                primary={item.label}
                className="head_mob_2"
                onClick={() => handleNavigation(item.path)}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon sx={{ color: 'white', fontSize: 40 }} />
      </Button>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        sx={{ color: 'white', backgroundColor: '#1D234B' }}
        className="mob_menu"
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
