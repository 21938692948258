import React, { useEffect } from 'react';

const SeoComponent = ({ 
  yoastHead, 
  customHead = '' 
}) => {
  useEffect(() => {
    const existingDynamicTags = document.querySelectorAll('[data-dynamic="true"]');
    existingDynamicTags.forEach(tag => tag.remove());

    if (yoastHead) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(yoastHead, 'text/html');
      const metaTags = doc.querySelectorAll('meta');
      metaTags.forEach(tag => {
        const metaTag = document.createElement('meta');
        Array.from(tag.attributes).forEach(attr => {
          metaTag.setAttribute(attr.name, attr.value);
        });

        metaTag.setAttribute('data-dynamic', 'true');
        document.head.appendChild(metaTag);
      });

      const titleTag = doc.querySelector('title');
      if (titleTag) {
        document.title = titleTag.textContent;
      }

      const scriptTags = doc.querySelectorAll('script[type="application/ld+json"]');
      scriptTags.forEach(script => {
        const scriptElement = document.createElement('script');
        scriptElement.type = 'application/ld+json';
        scriptElement.textContent = script.textContent;
        scriptElement.setAttribute('data-dynamic', 'true');
        document.head.appendChild(scriptElement);
      });

      const linkTags = doc.querySelectorAll('link');
      linkTags.forEach(link => {
        const linkTag = document.createElement('link');
        Array.from(link.attributes).forEach(attr => {
          linkTag.setAttribute(attr.name, attr.value);
        });

        linkTag.setAttribute('data-dynamic', 'true');
        document.head.appendChild(linkTag);
      });
    }

    if (customHead) {
      const customDoc = new DOMParser().parseFromString(customHead, 'text/html');
      const customTags = customDoc.body.children;
      
      Array.from(customTags).forEach(tag => {
        const newTag = document.createElement(tag.tagName);
        Array.from(tag.attributes).forEach(attr => {
          newTag.setAttribute(attr.name, attr.value);
        });

        newTag.textContent = tag.textContent;
        newTag.setAttribute('data-dynamic', 'true');
        document.head.appendChild(newTag);
      });
    }

    return () => {
      const dynamicTags = document.querySelectorAll('[data-dynamic="true"]');
      dynamicTags.forEach(tag => tag.remove());
    };
  }, [yoastHead, customHead]);

  return null;
};

export default SeoComponent;