import React, { useEffect } from 'react'
import BenifitsImg from "../assets/images/Benifits_img.png"

export default function BenefitsOfBecoming() {
   useEffect(() => {
    setTimeout(() => {
if (window.location.hash === "#collabra_opp") {
    
    const section = document.getElementById("collabra_opp");
    if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
}
    }, 1300)
     }, [])
  return (
    <div className='BenefitsOfBecoming_main_cont'>
        <div className='BenefitsOfBecoming_title'  data-aos="fade-up" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <p>
            BENEFITS OF BECOMING 
            A NIYOGIN PARTNER
            </p>
        </div>
        <div className="BenefitsOfBecoming_image_cont">
            <img src={BenifitsImg} alt=""   data-aos="fade-right" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000" />
            <div className='benifits_points_div'   data-aos="fade-left" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <ul >
                    <li>Recurring Income Stream</li>
                    <li>Weekly Payouts</li>
                    <li>Rewards and Recognition</li>
                    <li>Dedicated Relationship Manager</li>
                </ul>
            </div>
        </div>
        <div className='collabra_opp_div'  id="collabra_opp" data-aos="fade-up" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000">
            <h2>
            A Collaboration opportunity: 
            Inviting Professionals and 
            Financial Institutions
            </h2>
            <p>
            Becoming a <b>Niyogin Partner</b> with us is a <b>seamless</b> process - no 
            extensive <b>documentation</b> is required. Share your basic information 
            with us and pave the way to prosperity together.
            </p>
        </div>
    </div>
  )
}
