import React, { useEffect } from 'react'
import Aboutimagewithtext from '../components/About-image-with-text'
import { useLocation } from 'react-router-dom';
import About_circle from '../components/About_circle';
import Leadershipteam from '../components/Leadershipteam';

export default function Aboutus() {
    const location = useLocation();
    useEffect(() => {
     // const bodyClass = location.pathname === '/about-us' ? 'ab' : 'about-page';

      console.log("Navigated to About Us Page: ", location);
      
        if (location.hash === "#team_members") {
          setTimeout(() => {
              const element = document.getElementById("team_members");
              if (element) {
                  element.scrollIntoView({ behavior: "smooth" });
              }
          }, 100);
      }
  }, [location]);
    
  return (
    <div className='ABOUT_page_main'>
        <Aboutimagewithtext/>
        <About_circle/>
        <div id="team_members">
                <Leadershipteam />
            </div>
    </div>
  )
}
