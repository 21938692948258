import React from "react";
import PrivacyPolicyComponent from "../components/PrivacyPolicyComponent";

const PrivacyPolicyPage = () => {
  return (
    <div>
      <PrivacyPolicyComponent />
    </div>
  );
};

export default PrivacyPolicyPage;
