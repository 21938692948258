import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import backIcon from "../assets/images/backIcon.png";
import { DEVELOPMENT_WORDPRESS_DOMAIN } from "../utils/domain";
import SeoComponent from "../components/Seo";

export const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [yoastHead, setYoastHead] = useState("");
  const navigate = useNavigate();

  const fetchBlogBySlug = async (slug) => {
    try {
      const response = await fetch(`${DEVELOPMENT_WORDPRESS_DOMAIN}/wp-json/wp/v2/posts?slug=${slug}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      
      if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
        const data = JSON.parse(text);
        console.log("data[0].yoast_head", data[0].yoast_head)
        console.log(data[0].yoast_head)
        setYoastHead(data[0].yoast_head);
        if (Array.isArray(data) && data.length > 0) {
          setBlog(data[0]);
        } else {
          console.error("Blog post not found");
        }
      } else {
        const jsonStartIndex = text.indexOf('{');
        const jsonString = text.slice(jsonStartIndex).trim();
        const data = JSON.parse(jsonString);
        if (Array.isArray(data) && data.length > 0) {
          setBlog(data[0]);
          setYoastHead(data[0].yoast_head);
        } else {
          console.error("Blog post not found");
        }
      }
    } catch (error) {
      console.error("Error fetching blog by slug:", error);
    }
  };

  useEffect(() => {
    if (slug) {
      fetchBlogBySlug(slug);
    }
  }, [slug]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (!blog) return null;

  return (
    <div className="blog-detail">
      <SeoComponent yoastHead={blog.yoast_head} />
      <img
        src={backIcon}
        alt="Back"
        onClick={() => navigate(-1)} 
        className="back-button"
      />
      <div className="blog-detail-content-wrapper">
        <h2 className="blog-detail-title" dangerouslySetInnerHTML={{ __html: blog.title.rendered }} />
        <img src={blog.yoast_head_json?.og_image?.[0]?.url} alt={blog.title.rendered} className="blog-detail-image" />
        <div className="blog-detail-date">{formatDate(blog.date_gmt)}</div>
        <div className="blog-detail-content" dangerouslySetInnerHTML={{ __html: blog.content.rendered }} />
      </div>
    </div>
  );
};

export default BlogDetail;
