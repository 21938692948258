import React, { useEffect, useRef, useState } from "react";
import anime from "animejs/lib/anime.es.js";

const ContactForm = () => {
  const svgRef_contactForm = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    city: "",
    emailAddress: "",
    mobileNo: "",
    message: "",
  });
  const [formState, setFormState] = useState({
    errors: {},
    submissionMessage: "",
  });

  const MESSAGE_LIMIT = 400;


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect(); // Disconnect after the first trigger
          }
        });
      },
      { threshold: 0.5 }
    );

    if (svgRef_contactForm.current) {
      observer.observe(svgRef_contactForm.current);
    }

    return () => {
      if (observer && observer.current) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const paths3 = svgRef_contactForm.current?.querySelectorAll("path");

    if (isInView) {
      anime({
        targets: paths3,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 5000,
        delay: 0,
      });
    }
  }, [isInView]);

  

  const handleChange = (event) => {
    const { name, value } = event.target;

    const nameRegex = /^[A-Za-z\s]*$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^\d{10}$/;

    // Set form data
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validate based on the field name
    if (name === "first_name") {
      if (!value) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, first_name: "First name is required." },
        }));
      } else if (!nameRegex.test(value)) {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            first_name: "First name can only contain letters and spaces.",
          },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, first_name: undefined },
        }));
      }
    }

    if (name === "last_name") {
      if (!value) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, last_name: "Last name is required." },
        }));
      } else if (!nameRegex.test(value)) {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            last_name: "Last name can only contain letters and spaces.",
          },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, last_name: undefined },
        }));
      }
    }

    if (name === "city") {
      if (!value) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, city: "City is required." },
        }));
      } else if (!nameRegex.test(value)) {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            city: "City can only contain letters and spaces.",
          },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, city: undefined },
        }));
      }
    }

    if (name === "emailAddress") {
      if (!value) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, emailAddress: "Email is required." },
        }));
      } else if (!emailRegex.test(value)) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, emailAddress: "Email format is invalid." },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, emailAddress: undefined },
        }));
      }
    }

    if (name === "mobileNo") {
      const numericValue = value.replace(/\D/g, "");
      setFormData((prev) => ({ ...prev, mobileNo: numericValue }));

      if (numericValue.length !== 10) {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            mobileNo: "Mobile number must be exactly 10 digits.",
          },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, mobileNo: undefined },
        }));
      }
    }

    if (name === "message") {
      if (value.length > MESSAGE_LIMIT) {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            message: `Message cannot exceed ${MESSAGE_LIMIT} characters.`,
          },
        }));
      } else if (!value) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, message: "Message is required." },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, message: undefined },
        }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.first_name) newErrors.first_name = "First name is required.";
    if (!formData.last_name) newErrors.last_name = "Last name is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.emailAddress) newErrors.emailAddress = "Email is required.";
    if (formData.mobileNo.length !== 10)
      newErrors.mobileNo = "Mobile number is required.";
    if (formData.message.length > MESSAGE_LIMIT) {
      newErrors.message = `Message cannot exceed ${MESSAGE_LIMIT} characters.`;
    }

    setFormState((prev) => ({
      ...prev,
      errors: newErrors,
    }));
    return Object.keys(newErrors).length === 0;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop if validation fails
    }

    try {
      const response = await fetch(
        "https://pisoauat.niyogin.in/gates/1.0/sweeps/createContactUs",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic eFNncWg6R0tQZWpYRX1j",
          },
          body: JSON.stringify({
            ...formData,
            source: "web",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      setFormState((prev) => ({
        ...prev,
        submissionMessage: "Form submitted successfully!",
      }));

      // Reset form data
      setFormData({
        first_name: "",
        last_name: "",
        city: "",
        emailAddress: "",
        mobileNo: "",
        message: "",
      });

      setFormState((prev) => ({ ...prev, errors: {} }));
    } catch (error) {
      console.error("Error:", error);
      setFormState((prev) => ({
        ...prev,
        submissionMessage: "Error submitting form. Please try again.",
      }));
    }
  };

  return (
    <div
      className="contact-form-wrapper"
      data-aos="fade-up"
      data-aos-offset="10"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
   <div className="svg_div_contact-form">
        <svg
          width="100%"
          ref={svgRef_contactForm}
          // height="100%"
          viewBox="0 0 1425 885"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="1425"
            height="885"
          >
            <rect width="1425" height="885" fill="#C4C4C4"></rect>
          </mask>
          <g mask="url(#mask0)">
            <path
              d="M1234.72 1197.88C1234.72 1197.88 1252.8 647.228 685.93 636.622C607.34 635.135 58.4301 618.807 -395.928 605.028"
              stroke="#24A0D0"
              stroke-opacity="0.2"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1276.39 1137.72C1276.39 1137.72 1249.57 635.389 704.334 625.201C628.193 623.771 65.1712 624.567 -396.711 625.544"
              stroke="#24A0D0"
              stroke-opacity="0.3"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1317.95 1077.56C1317.95 1077.56 1246.12 623.458 722.766 613.651C649.074 612.278 71.9185 630.757 -397.627 646.509"
              stroke="#24A0D0"
              stroke-opacity="0.4"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1359.63 1017.41C1359.63 1017.41 1242.89 611.64 741.308 602.232C670.181 600.919 78.7543 637.66 -398.342 668.275"
              stroke="#24A0D0"
              stroke-opacity="0.5"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1401.31 957.252C1401.31 957.252 1239.55 599.711 759.832 590.684C691.27 589.431 85.4117 644.99 -399.194 690.599"
              stroke="#24A0D0"
              stroke-opacity="0.5"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1442.87 897.093C1442.87 897.093 1236.24 587.762 778.259 579.263C712.376 578.072 91.8623 652.897 -400.077 713.74"
              stroke="#24A0D0"
              stroke-opacity="0.5"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1484.55 836.937C1484.55 836.937 1232.88 575.941 796.806 567.716C733.604 566.587 98.392 661.151 -400.973 737.205"
              stroke="#24A0D0"
              stroke-opacity="0.5"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1526.11 776.779C1526.11 776.779 1229.56 564.013 815.21 556.294C754.83 555.124 104.784 669.983 -401.9 761.488"
              stroke="#24A0D0"
              stroke-opacity="0.7"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1567.79 716.602C1567.79 716.602 1226.22 552.171 833.753 544.855C776.192 543.75 111.156 679.353 -402.843 786.201"
              stroke="#24A0D0"
              stroke-opacity="0.7"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1609.46 656.447C1609.46 656.447 1223 540.224 852.301 533.307C797.419 532.265 117.371 689.194 -403.808 811.496"
              stroke="#24A0D0"
              stroke-opacity="0.7"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1651.03 596.288C1651.03 596.288 1219.55 528.293 870.71 521.756C818.763 520.783 123.453 699.485 -404.791 837.242"
              stroke="#24A0D0"
              stroke-opacity="0.7"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1692.7 536.133C1692.7 536.133 1216.32 516.475 889.137 510.335C840.239 509.433 129.541 710.227 -405.8 863.7"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1734.38 475.977C1734.38 475.977 1212.99 504.546 907.657 498.895C861.694 498.06 135.584 721.55 -406.845 890.457"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1775.94 415.818C1775.94 415.818 1209.64 492.726 926.087 487.366C883.175 486.581 141.387 733.084 -407.862 917.711"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1817.62 355.663C1817.62 355.663 1206.31 480.776 944.631 475.926C904.79 475.235 147.284 745.201 -408.927 945.61"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1859.18 295.504C1859.18 295.504 1202.97 468.955 963.039 464.397C926.368 463.65 152.909 757.764 -410.002 973.768"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1900.86 235.349C1900.86 235.349 1199.63 457.027 981.581 452.957C947.961 452.281 158.66 770.675 -411.094 1002.38"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1942.42 175.169C1942.42 175.169 1196.29 445.056 1000.01 441.407C969.697 440.808 164.251 784.143 -412.207 1031.55"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1984.1 115.013C1984.1 115.013 1192.95 433.235 1018.56 429.967C991.404 429.464 169.737 797.953 -413.333 1061.06"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1978.63 75.3254C1799.92 152.368 1167.73 420.925 1037.08 418.44C1013.25 417.992 175.184 812.127 -414.358 1090.9"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1859.79 90.4618C1590.81 212.846 1145.58 408.667 1055.51 407.02C1034.96 406.649 180.496 826.86 -415.63 1121.23"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1737.49 115.88C1466.63 245.395 1136.37 396.688 1074.03 395.472C1056.79 395.178 185.661 841.783 -416.822 1151.86"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1635.94 138.959C1388.04 263.407 1135.12 384.679 1092.46 383.922C1078.64 383.707 190.947 857.183 -417.982 1182.86"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1556.11 157.395C1336.8 272.943 1138.62 373.018 1111.01 372.503C1100.61 372.261 196.1 873.053 -419.072 1214.45"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1494.67 171.24C1303.37 277.055 1145.68 361.31 1129.43 361.061C1122.48 360.899 201.004 889.37 -420.377 1246.22"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
            <path
              d="M1447.67 181.09C1281.68 277.584 1155.17 349.681 1147.95 349.621C1144.42 349.538 205.965 906.012 -421.51 1278.34"
              stroke="#24A0D0"
              stroke-width="0.7937"
              stroke-miterlimit="10"
            ></path>
          </g>
        </svg>
      </div>
      <div className="contact-form-header">
        <p className="form-title">CONTACT US</p>
      </div>

      <div className="contact-form-container">
        <form className="contact-form" onSubmit={submitForm}>
          <input
            type="text"
            name="first_name"
            placeholder="First Name"
            className="form-input"
            value={formData.first_name}
            onChange={handleChange}
          />
          {formState.errors.first_name && (
            <p className="error-text">{formState.errors.first_name}</p>
          )}

          <input
            type="text"
            name="last_name"
            placeholder="Last Name"
            className="form-input"
            value={formData.last_name}
            onChange={handleChange}
          />
          {formState.errors.last_name && (
            <p className="error-text">{formState.errors.last_name}</p>
          )}

          <input
            type="text"
            name="city"
            placeholder="City"
            className="form-input"
            value={formData.city}
            onChange={handleChange}
          />
          {formState.errors.city && (
            <p className="error-text">{formState.errors.city}</p>
          )}

          <input
            type="email"
            name="emailAddress"
            placeholder="Email"
            className="form-input"
            value={formData.emailAddress}
            onChange={handleChange}
          />
          {formState.errors.emailAddress && (
            <p className="error-text">{formState.errors.emailAddress}</p>
          )}

          <input
            type="text"
            name="mobileNo"
            placeholder="Mobile Number"
            className="form-input"
            value={formData.mobileNo}
            onChange={handleChange}
          />
          {formState.errors.mobileNo && (
            <p className="error-text">{formState.errors.mobileNo}</p>
          )}

          <textarea
            name="message"
            placeholder="Message"
            className="form-textarea"
            value={formData.message}
            onChange={handleChange}
          />
          {formState.errors.message && (
            <p className="error-text">{formState.errors.message}</p>
          )}

          <div>
            {formState.submissionMessage && (
              <p
                className={`submission-message ${
                  formState.submissionMessage.includes("Error")
                    ? "error-message"
                    : "success-message"
                }`}
              >
                {formState.submissionMessage}
              </p>
            )}

            <button type="submit" className="form-button">
              Send
            </button>
          </div>
        </form>
      </div>

      <p className="form-subtitle">OUR TEAM WILL CONNECT WITH YOU SHORTLY</p>
    </div>
  );
};

export default ContactForm;
