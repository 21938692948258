import React from 'react'
import MediaResources from '../components/MediaResources'
import PressResources from '../components/PressResources'
import FintechNews from '../components/FintechNews'
import PressRelease from '../components/PressRelease'

const NewsRoom = () => {
  return (
    <div>
      <MediaResources/>
      <FintechNews/>
      <PressRelease/>
      <PressResources/>
    </div>
  )
}

export default NewsRoom
