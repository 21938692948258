import React from 'react'
import Homebanner from '../components/Home_banner'
import Oursubsidiaries from '../components/Our-subsidiaries'
import NIYOGINfeed from '../components/NIYOGINfeed'
import Niyoginnews from '../components/Niyoginnews'
import PartnersLogo from '../components/PartnersLogo'

export default function home() {
  return (
    <>
        <div>
            <Homebanner/>
            <Oursubsidiaries/>
            <PartnersLogo/>
            <Niyoginnews/>
            
        </div>
        
    </>
  )
}
