import anime from 'animejs';
import React, { useEffect, useRef } from 'react'

export default function OurDistributionPlatform() {
  const svgRef3 = useRef(null);

  useEffect(() => {

  const svg3 = svgRef3.current;

  const paths3 = svgRef3.current.querySelectorAll('path');


  anime({
      targets: paths3,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 5000,
      direction: 'normal'
      // loop: true,

    });

  })
  return (
    <div className="distribution_position_div">
      <div className='OurDistributionPlatform'>
          <div className='OurDistributionPlatform_title'  data-aos="fade-up" data-aos-offset="10"
                      data-aos-easing="ease-in-sine" data-aos-duration="1000">
              <h2>OUR DISTRIBUTION PLATFORM</h2>
          </div>
          <div className="OurDistributionPlatform_p"  data-aos="fade-down" data-aos-offset="10"
                      data-aos-easing="ease-in-sine" data-aos-duration="1500">
              <p>
              NiyoBlu, our distribution platform, offers end-to-end digital distribution of financial 
              products and services to MSMEs through a large network of financial experts, 
              functionalities that enable an enriched and engaging experience for partners by 
              practising professionals, financial advisors and consultants. The platform boasts 
              providing access to product manuals, analytical dashboards, and much more.
              </p>
          </div>
          <div className='ourDistribu_video'  data-aos="zoom-in" data-aos-offset="10"
                      data-aos-easing="ease-in-sine" data-aos-duration="1000">
              <iframe width="560" height="315" 
              src="https://www.youtube.com/embed/P7_J2zMFp_U?si=fs2puX4pGQo0AyEW" title="YouTube video player" 
              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
      </div>
      <div className='svg_animation1 distri_svg2'>
                <svg width="100%" ref={svgRef3} id="my_svg_element" height="100%" viewBox="0 0 1425 885" fill="none" xmlns="http://www.w3.org/2000/svg" class=" drawsvg-initialized " >
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1425" height="885">
                            <rect width="1425" height="885" fill="#C4C4C4"></rect>
                            </mask>
                            <g mask="url(#mask0)">
                            <path d="M1234.72 1197.88C1234.72 1197.88 1252.8 647.228 685.93 636.622C607.34 635.135 58.4301 618.807 -395.928 605.028" stroke="#24A0D0" stroke-opacity="0.2" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1276.39 1137.72C1276.39 1137.72 1249.57 635.389 704.334 625.201C628.193 623.771 65.1712 624.567 -396.711 625.544" stroke="#24A0D0" stroke-opacity="0.3" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1317.95 1077.56C1317.95 1077.56 1246.12 623.458 722.766 613.651C649.074 612.278 71.9185 630.757 -397.627 646.509" stroke="#24A0D0" stroke-opacity="0.4" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1359.63 1017.41C1359.63 1017.41 1242.89 611.64 741.308 602.232C670.181 600.919 78.7543 637.66 -398.342 668.275" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1401.31 957.252C1401.31 957.252 1239.55 599.711 759.832 590.684C691.27 589.431 85.4117 644.99 -399.194 690.599" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1442.87 897.093C1442.87 897.093 1236.24 587.762 778.259 579.263C712.376 578.072 91.8623 652.897 -400.077 713.74" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1484.55 836.937C1484.55 836.937 1232.88 575.941 796.806 567.716C733.604 566.587 98.392 661.151 -400.973 737.205" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1526.11 776.779C1526.11 776.779 1229.56 564.013 815.21 556.294C754.83 555.124 104.784 669.983 -401.9 761.488" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1567.79 716.602C1567.79 716.602 1226.22 552.171 833.753 544.855C776.192 543.75 111.156 679.353 -402.843 786.201" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1609.46 656.447C1609.46 656.447 1223 540.224 852.301 533.307C797.419 532.265 117.371 689.194 -403.808 811.496" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1651.03 596.288C1651.03 596.288 1219.55 528.293 870.71 521.756C818.763 520.783 123.453 699.485 -404.791 837.242" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1692.7 536.133C1692.7 536.133 1216.32 516.475 889.137 510.335C840.239 509.433 129.541 710.227 -405.8 863.7" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1734.38 475.977C1734.38 475.977 1212.99 504.546 907.657 498.895C861.694 498.06 135.584 721.55 -406.845 890.457" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1775.94 415.818C1775.94 415.818 1209.64 492.726 926.087 487.366C883.175 486.581 141.387 733.084 -407.862 917.711" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1817.62 355.663C1817.62 355.663 1206.31 480.776 944.631 475.926C904.79 475.235 147.284 745.201 -408.927 945.61" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1859.18 295.504C1859.18 295.504 1202.97 468.955 963.039 464.397C926.368 463.65 152.909 757.764 -410.002 973.768" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1900.86 235.349C1900.86 235.349 1199.63 457.027 981.581 452.957C947.961 452.281 158.66 770.675 -411.094 1002.38" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1942.42 175.169C1942.42 175.169 1196.29 445.056 1000.01 441.407C969.697 440.808 164.251 784.143 -412.207 1031.55" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1984.1 115.013C1984.1 115.013 1192.95 433.235 1018.56 429.967C991.404 429.464 169.737 797.953 -413.333 1061.06" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1978.63 75.3254C1799.92 152.368 1167.73 420.925 1037.08 418.44C1013.25 417.992 175.184 812.127 -414.358 1090.9" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1859.79 90.4618C1590.81 212.846 1145.58 408.667 1055.51 407.02C1034.96 406.649 180.496 826.86 -415.63 1121.23" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1737.49 115.88C1466.63 245.395 1136.37 396.688 1074.03 395.472C1056.79 395.178 185.661 841.783 -416.822 1151.86" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1635.94 138.959C1388.04 263.407 1135.12 384.679 1092.46 383.922C1078.64 383.707 190.947 857.183 -417.982 1182.86" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1556.11 157.395C1336.8 272.943 1138.62 373.018 1111.01 372.503C1100.61 372.261 196.1 873.053 -419.072 1214.45" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1494.67 171.24C1303.37 277.055 1145.68 361.31 1129.43 361.061C1122.48 360.899 201.004 889.37 -420.377 1246.22" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1447.67 181.09C1281.68 277.584 1155.17 349.681 1147.95 349.621C1144.42 349.538 205.965 906.012 -421.51 1278.34" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            </g>
                        </svg>  
        </div>
    </div>
  )
}
