import './App.css';
import Home from "../src/pages/home.jsx"
import Header from './components/header.jsx';
import Footer from './components/footer.jsx';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Nopage from './pages/Nopage.jsx';
import "./assets/css/main.css"
import './assets/css/style.css'
// importing aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import Aboutus from './pages/Aboutus.jsx';
import NIYOGINfeed from './components/NIYOGINfeed.jsx';
import EmbeddedFinance from './pages/EmbeddedFinance.jsx';

import Career from './pages/Career.jsx';
import DistributionNetwork from './pages/DistributionNetwork.jsx';

import NewsRoom from './pages/NewsRoom.jsx';
import TermsConditions from './pages/TermsConditions.jsx';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage.jsx';
import ScrollToTop from './components/ScrollToTop.jsx';
import ContactUs from './pages/ContactUs.jsx';

import Blogs from './pages/Blogs.jsx';

import InvestorRelation from './pages/InvestorRelation.jsx';
import BlogDetail from './pages/BlogDetail.jsx';
import { HelmetProvider } from 'react-helmet-async';

const Redirect301 = ({ to }) => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.httpEquiv = 'refresh';
    meta.content = `0;url=${to}`;
    document.head.appendChild(meta);
    document.title = `Redirecting to ${to}`;
    return () => {
      document.head.removeChild(meta);
    };
  }, [to]);
  
  return null;
};

const ExternalRedirect301 = ({ url }) => {
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.httpEquiv = 'refresh';
    meta.content = `0;url=${url}`;
    document.head.appendChild(meta);
    
    document.title = `Redirecting to ${url}`;
    
    return () => {
      document.head.removeChild(meta);
    };
  }, [url]);

  return null;
};

const Layout = () => {
  const location = useLocation();

  // Determine the current path and set classes
  const headerClass = location.pathname === '/' ? 'header-home' : 'header-other';
  const webPath = location.pathname;

  return (
    <>
      <Header className={headerClass} pathweb={webPath} />
      <ScrollToTop/>
      <Routes>
        <Route path="/home" element={<Redirect301 to="/" />} />
        <Route path="/company" element={<Redirect301 to="/about-us" />} />
        <Route path="/enquiry" element={<Redirect301 to="/contact-us" />} />
        <Route path="/apply-now" element={<Redirect301 to="/contact-us" />} />
        <Route path="/support1" element={<Redirect301 to="/contact-us" />} />
        <Route path="/en/careers/-/categories/61249" element={<Redirect301 to="/careers" />} />
        <Route path="/web/guest/terms-conditions" element={<Redirect301 to="/terms-conditions" />} />
        <Route path="/customer-onboarding" element={<Redirect301 to="/distribution-network#collabra_opp" />} />
        <Route path="/en/company" element={<Redirect301 to="/about-us" />} />
        <Route path="/customer-onboarding1" element={<Redirect301 to="/distribution-network#collabra_opp" />} />
        <Route path="/embedded-lending" element={<Redirect301 to="/Embedded-Finance" />} />
        <Route path="/blogs/blogposts/Investor-Relations" element={<Redirect301 to="/Investor-Relations" />} />
        {/* <Route path="/blogs/fintech-disruption-or-collaboration" element={<Redirect301 to="/blogs/blogposts/fintech-disruption-or-collaboration" />} />

        <Route path="/blogs/how-are-regulatory-changes-impacting-fintech" element={<Redirect301 to="/blogs/blogposts/how-are-regulatory-changes-impacting-fintech" />} />

        <Route path="/blogs/role-of-ai-in-credit-scoring" element={<Redirect301 to="/blogs/blogposts/role-of-ai-in-credit-scoring" />} />

        <Route path="/blogs/the-rise-of-microfinance-in-india" element={<Redirect301 to="/blogs/blogposts/the-rise-of-microfinance-in-india" />} />

        <Route path="/blogs/where-is-co-lending-heading-in-the-next-five-years" element={<Redirect301 to="/blogs/blogposts/where-is-co-lending-heading-in-the-next-five-years" />} />

        <Route path="/blogs/understanding-the-psychology-of-financial-decision-making" element={<Redirect301 to="/blogs/blogposts/understanding-the-psychology-of-financial-decision-making" />} />
        <Route path="/blogs/what-is-alternative-data-for-credit-underwriting-and-how-your-lenders-gain-access-to-it" element={<Redirect301 to="/blogs/blogposts/what-is-alternative-data-for-credit-underwriting-and-how-your-lenders-gain-access-to-it" />} />
        <Route path="/blogs/key-functions-of-retail-banking-vs-corporate-banking" element={<Redirect301 to="/blogs/blogposts/key-functions-of-retail-banking-vs-corporate-banking" />} />

        <Route path="/blogs/7-types-of-risk-management-you-must-know-about" element={<Redirect301 to="/blogs/blogposts/7-types-of-risk-management-you-must-know-about" />} />

        <Route path="/blogs/can-microfinance-lead-to-women-empowerment" element={<Redirect301 to="/blogs/blogposts/can-microfinance-lead-to-women-empowerment" />} />

        <Route path="/blogs/what-is-embedded-finance-and-how-does-it-benefit-the-fintech-industry" element={<Redirect301 to="/blogs/blogposts/what-is-embedded-finance-and-how-does-it-benefit-the-fintech-industry" />} />

        <Route path="/blogs/growth-of-retail-lending-and-its-impact-on-underserved-segments" element={<Redirect301 to="/blogs/blogposts/growth-of-retail-lending-and-its-impact-on-underserved-segments" />} />

        <Route path="/blogs/7-advantages-of-taking-a-business-loan" element={<Redirect301 to="/blogs/blogposts/7-advantages-of-taking-a-business-loan" />} />

        <Route path="/blogs/iserveu-serving-the-rural" element={<Redirect301 to="/blogs/blogposts/iserveu-serving-the-rural" />} />

        <Route path="/blogs/financial-literacy-and-the-need-for-financial-inclusion" element={<Redirect301 to="/blogs/blogposts/financial-literacy-and-the-need-for-financial-inclusion" />} />

        <Route path="/blogs/financial-inclusion-the-need-and-future" element={<Redirect301 to="/blogs/blogposts/financial-inclusion-the-need-and-future" />} />

        <Route path="/blogs/why-is-india-spearheading-the-fintech-revolution" element={<Redirect301 to="/blogs/blogposts/why-is-india-spearheading-the-fintech-revolution" />} />

        <Route path="/blogs/challenges-and-opportunities-for-nbfcs-in-india" element={<Redirect301 to="/blogs/blogposts/challenges-and-opportunities-for-nbfcs-in-india" />} />

        <Route path="/blogs/what-is-decentralized-finance-defi-and-how-does-it-work" element={<Redirect301 to="/blogs/blogposts/what-is-decentralized-finance-defi-and-how-does-it-work" />} />

        <Route path="/blogs/5-uses-of-financial-analysis" element={<Redirect301 to="/blogs/blogposts/5-uses-of-financial-analysis" />} />
        <Route path="/blogs/the-significance-of-regional-rural-banks-in-india" element={<Redirect301 to="/blogs/blogposts/the-significance-of-regional-rural-banks-in-india" />} />

        <Route path="/blogs/role-of-ai-in-supply-chain-finance-management" element={<Redirect301 to="/blogs/blogposts/role-of-ai-in-supply-chain-finance-management" />} />

        <Route path="/blogs/how-is-fintech-revolutionizing-credit-scores" element={<Redirect301 to="/blogs/blogposts/how-is-fintech-revolutionizing-credit-scores" />} />

        <Route path="/blogs/corporate-financing-vs-investment-banking-whats-the-difference" element={<Redirect301 to="/blogs/blogposts/corporate-financing-vs-investment-banking-whats-the-difference" />} />

        <Route path="/blogs/alternative-finance-instruments-how-do-they-help-smes-grow" element={<Redirect301 to="/blogs/blogposts/alternative-finance-instruments-how-do-they-help-smes-grow" />} />

        <Route path="/blogs/fraud-detection-and-prevention-in-fintech" element={<Redirect301 to="/blogs/blogposts/fraud-detection-and-prevention-in-fintech" />} />

        <Route path="/blogs/what-is-credit-risk-evaluation" element={<Redirect301 to="/blogs/blogposts/what-is-credit-risk-evaluation" />} /> */}




        








        <Route path="/en/unsecured-business-loans" element={<Redirect301 to="/Distribution-network" />} />
        <Route path="/platform-offerings" element={<Redirect301 to="/Embedded-Finance" />} />
        <Route path="/investors" element={<Redirect301 to="/Investor-Relations" />} />
        <Route path="/newsroom" element={<Redirect301 to="/news-room" />} />
        <Route path="/web/guest/become-a-partner" element={<Redirect301 to="/distribution-network#collabra_opp" />} />
        <Route path="/faq" element={<Redirect301 to="/Investor-Relations" />} /> 

        
        <Route path="/rural-tech" element={<ExternalRedirect301 url="https://iserveu.in/" />} />
        <Route path="/urban-tech" element={<ExternalRedirect301 url="https://moneyfront.in/" />} />
        <Route path="/investment" element={<ExternalRedirect301 url="https://moneyfront.in/" />} />
        <Route path="/iserveu" element={<ExternalRedirect301 url="https://iserveu.in/" />} />
        <Route path="/moneyfrontfs" element={<ExternalRedirect301 url="https://moneyfront.in/" />} />

        {/* Main Routes */}
        <Route index element={<Home />} />
        <Route path="about-us" element={<Aboutus />} />
        <Route path="terms-conditions" element={<TermsConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="Embedded-Finance" element={<EmbeddedFinance />} />
        <Route path="careers" element={<Career />} />
        <Route path="distribution-network" element={<DistributionNetwork />} />
        <Route path="Investor-Relations" element={<InvestorRelation />} />
        <Route path="news-room" element={<NewsRoom />} />
        <Route path="blogs" element={<Blogs />} />
        <Route path="blogs/:slug" element={<BlogDetail />} />

        {/* 404 Route */}
        <Route path="*" element={<Nopage />} />
      </Routes>
      <NIYOGINfeed/>
      <Footer/>
    </>
  );
};

function App() {
  useEffect(() => {
    // AOS.refreshHard();
    AOS.init();
  }, []);

  return (
    <HelmetProvider>
    <div className="App">
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;