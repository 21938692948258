import React from "react";
import { useNavigate } from "react-router-dom";


const PressRelease = () => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="press-release-container"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1500"
      >
        <h1 className="press-release-title">NIYOGIN PRESS RELEASES</h1>
        <p className="press-release-description">
          We believe in sharing important milestones with the world. All our
          press releases are collated here.
        </p>

        <div className="press-release-grid">
          <div className="press-release-item">
            <p>
              Niyogin Fintech to acquire <strong>51% strategic stake</strong> in
              iServeU
            </p>
            <button className="view-button" onClick={()=>{window.location.href='https://www.niyogin.com/documents/20143/136527/niyogin-fintech-to-acquire-51-percent-strategic-stake-in-iserveu.pdf/58cba622-a661-1968-e083-bfb927ff9fe1'}}>VIEW</button>
          </div>

          <div className="vertical-line"></div>

          <div className="press-release-item">
            <p>
              Niyogin Fintech <br/> to raise <br/><strong>Rs. 235 Crore</strong>
            </p>
            <button className="view-button" onClick={()=>{window.location.href='https://economictimes.indiatimes.com/small-biz/money/niyogin-fintech-to-raise-rs-235-crore/articleshow/58966343.cms??from=mdr'}}>VIEW</button>
          </div>

          <div className="vertical-line"></div>

          <div className="press-release-item">
            <p>
              Niyogin Fintech raising <strong>$36 mn</strong> from Ward Ferry,
              Sabre Capital, and others
            </p>
            <button className="view-button" onClick={()=>{window.location.href='https://www.vccircle.com/niyogin-fintech-raising-36-mn-from-ward-ferry-sabre-capital-and-others'}}>VIEW</button>
          </div>
        </div>

        <div className="press-release-email-subscription">
          <label htmlFor="email">Email:</label>
          {/* <input type="email" id="email" name="email" placeholder="Enter your email" /> */}
          <input type="email" id="email" name="email" />
          <button className="subscribe-button">SUBSCRIBE</button>
        </div>
      </div>

      <div
        className="brand-container"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1500"
      >
        <p className="brand-title">MEDIA RESOURCES & NEWS CENTER</p>
        <p className="brand-main-title">BRAND NIYOGIN</p>
        <p className="brand-description">
          We have put together all that you need to understand about our brand
          from logos to screenshots of our platform and a general overview of
          niyogin, in one place to make covering us convenient for anyone.
        </p>
        <button className="brand-button">
          <a href="#press-title">
          VIEW BRAND COMMUNICATION
          </a>
          </button>
      </div>
    </>
  );
};

export default PressRelease;
