import React, { useEffect, useState } from 'react'
import shapeDarkImg from "../assets/images/InvestorDarkShape.png"
import shapeLightImg from "../assets/images/InvestorLightShape.png"
import GrossGTv from "../assets/images/Gross_Gtv.png"
import NFl from "../assets/images/NFL.png"
import LtdLoans from "../assets/images/LtdLoans.png"
import NiyoPart from "../assets/images/NiyoPart.png"
import { DEVELOPMENT_WORDPRESS_DOMAIN } from '../utils/domain'

export default function InvestorBusinessValue() {
  const [data, setData] = useState(null); // To store fetched data
  const [loadingData, setLoadingData] = useState(null); // To handle the loading state
  const [error, setError] = useState(null); // To handle errors
  const [businessData, setBusinessData] = useState(null)

  useEffect(() => {
    // Fetch data from an API
    fetch(`${DEVELOPMENT_WORDPRESS_DOMAIN}/wp-json/wp/v2/pages?slug=investors-relations`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // Parse the JSON data
      })
      .then(async (text) => {

        if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
          console.log("already parsed IR DATA",  JSON.parse(text)[0].acf.business_Volumes);
          setBusinessData(await JSON.parse(text)[0].acf.business_Volumes)
          // setNewsdata(JSON.parse(data));  // If the response is valid JSON
        } else {
          const cleanedText = text.replace(/<[^>]*>/g, '')
          const jsonStartIndex = cleanedText.indexOf('[');
          const jsonString = cleanedText.slice(jsonStartIndex).trim();
          const data = await JSON.parse(jsonString);
          console.log("newdata from  IR -------", data)
          console.log("data IR", data)
          setLoadingData(data)
          setData(data[0]?.acf?.investor_services?.content); // Set the data
          // setLoading(false); // Stop loading once data is fetched
          console.log("<<<<<<<<<data ===== >>>>>> IR >>>> ", data[0].acf)
          setBusinessData(data[0].acf.business_Volumes)
  
      
        }
   
      })
      .catch((error) => {
        setError(error); // Handle any errors
        // setLoading(false);
      });
  }, []); // Empty dependency array means it runs once when the component mounts
  return (
    <div className='InvestorBusinessValue_main_container'>
      <div className="Investor_titleClass">
        <h2>BUSINESS VOLUMES AS ON Q3 FY25</h2>
      </div>
      <div className="investor_fourh_shapes_div">
        {businessData && businessData.length > 0 ? (
          businessData.map((data, index) => {
            // console.log("Business Title: ", data?.title_bussiness);

            return (<>
              <div className="two_investor_cont" data-title={index + 1} >
                <div className="shape_investorBusiness_div">
                  <img src={shapeDarkImg} alt="" />
                  <div className="cicle_icons">
                    <img src={data?.business_image.url} alt={data?.business_image.title} />
                  </div>
                  <div className="main_shape_titleInvestor">{data?.title_bussiness}</div>
                  <div className='number_div'>{data?.business_number}</div>
                </div>
              </div>

            </>
            );
          })
        ) : (
          <p></p>
        )}


      </div>
      <div className="investorValue_paragaph" dangerouslySetInnerHTML={{ __html: data }} >

      </div>
    </div>
  )
}
