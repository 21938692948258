import React from 'react'
import InvestorBanner from '../components/InvestorBanner'
import InvestorBusinessValue from '../components/InvestorBusinessValue'
import InvestorDarkBlue from '../components/InvestorDarkBlue'
import InvestorLightBlue from '../components/InvestorLightBlue'
import InvestorRetailsTabs from '../components/InvestorRetailsTabs'

export default function InvestorRelation() {
  return (
    <div className='InvestorRelation_main_container'>
        <InvestorBanner/>
        <InvestorBusinessValue/>
        <InvestorRetailsTabs/>
        <InvestorDarkBlue/>
        <InvestorLightBlue/>
    </div>
  )
}
