import React from 'react'
import InvestorBannnerImg from "../assets/images/investor_relation_banner.png"

export default function InvestorBanner() {
  return (
    <div className='InvestorBanner_main_cont'>
        <div className="Investor_rel_banner_div">
            <img src={InvestorBannnerImg} alt="" />
        </div>
        <div className='Investor_ban_title'>
            <h2>
                INVESTOR 
                RELATIONS
            </h2>
        </div>
    </div>
  )
}
