import React, { useEffect } from 'react'
import DisBannerwithText from '../components/DisBannerwithText'
import OurOfferSection from '../components/OurOfferSection'
import OurDistributionPlatform from '../components/OurDistributionPlatform'
import BenefitsOfBecoming from '../components/BenefitsOfBecoming'
import PartnersLogo from '../components/PartnersLogo'
import AccordianOurOffer from '../components/AccordianOurOffer'

export default function DistributionNetwork() {
  
  return (
    <div>
        <DisBannerwithText/>
        <OurOfferSection/>
        <PartnersLogo title={"OUR FINANCIAL AND INVESTMENT PARTNERS"} cls={"distrib_class"}/>
        <OurDistributionPlatform/>
        <BenefitsOfBecoming/>
        <AccordianOurOffer/>
    </div>
  )
}
