import React from 'react'
import cir1_bl from "../assets/images/light_bl_circle.png"
import cir2_gray from "../assets/images/light_gray_circle.png"

export default function About_circle() {
  return (
    <div className='About_circle_main_div'>
        <div className="circ_abt_img_txt">
            <div className="img_and_tex_connt_abt"  data-aos="zoom-in" data-aos-offset="10"
     data-aos-easing="ease-in-sine" data-aos-duration="1500">
                <div className='cir_about_img'>
                    <img src={cir1_bl} alt="" />
                </div>
                <div className="text_tit_p_abt">
                    <h2>TRUST</h2>
                    <p>Trust is the bedrock of our business. It underpins every interaction we have with 
                    employees, customers, and partners.
                    </p>
                </div>
            </div>
            <div className="img_and_tex_connt_abt" data-aos="zoom-in" data-aos-offset="10"
     data-aos-easing="ease-in-sine" data-aos-duration="1500">
                <div className='cir_about_img'>
                    <img src={cir2_gray} alt="" />
                </div>
                <div className="text_tit_p_abt">
                    <h2>AGILITY</h2>
                    <p>We leverage agility in financial technology to make real-time decisions, giving 
                    your business a strategic edge in the fast-paced financial landscape.
                    </p>
                </div>
            </div>
            <div className="img_and_tex_connt_abt" data-aos="zoom-in" data-aos-offset="10"
     data-aos-easing="ease-in-sine" data-aos-duration="1500">
                <div className='cir_about_img'>
                    <img src={cir2_gray} alt="" />
                </div>
                <div className="text_tit_p_abt">
                <h2>INNOVATION</h2>
                    <p>We're financial service providers constantly pushing boundaries to deliver 
                    cutting-edge solutions that transform the way people manage their finances. 
                    </p>
                    
                </div>
            </div>
            <div className="img_and_tex_connt_abt" data-aos="zoom-in" data-aos-offset="10"
     data-aos-easing="ease-in-sine" data-aos-duration="1500">
                <div className='cir_about_img'>
                    <img src={cir1_bl} alt="" />
                </div>
                <div className="text_tit_p_abt">
                <h2>CUSTOMER CENTRICITY</h2>
                    <p>Our fintech platform is built on delivering exceptional customer experience. We 
                    leverage technology to  deliver insightful and seamless financial solutions.
                    </p>
                </div>
            </div>
        </div>
    </div>
  )
}
