import React from 'react'
import part_say_vec from "../assets/images/partner_say_vector.png"
import hover_vector from "../assets/images/what_say_hoverVector.png"
import BIZONGO_logo from "../assets/images/BIZONGO_logo.png"
import KhataBook_logo from "../assets/images/KB-Horizontal-Tagline.png"
import karmalife_logo from "../assets/images/karmalife.svg"
import close_vector from "../assets/images/Menu_close.svg"

export default function PartnerOurSay() {
    const handhoverclick = (e) => {
        console.log("hoverd clockk --- ", e)
        const circles = document.querySelectorAll(".active");

        circles.forEach((circle, ind) => {
            circle.classList.remove("active");
        });
        e.target.classList.add("active")
        // if(){

        // }
    }
    return (
        <div className='PartnerOurSay_main_cont'>
            <div className='tit_partner_say'>
                <p>WHAT OUR PARTNERS SAY</p>
            </div>
            <div className='image_of_part_and_txt'>
                <div className='imag_vec_partner_say' data-aos="fade-right" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000" >
                    <img src={part_say_vec} alt="" />
                </div>
                <div className='text_of_part_say' data-aos="fade-left" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000" >
                    <ul>
                        <li onClick={(e) => handhoverclick(e)}>
                            <p>KHATABOOK</p>
                            <div className='div_on_hover'>
                                <div>
                                    <img src={KhataBook_logo} alt="" />
                                    <p>
                                        Khatabook, a ledger keeping application, is able to fulfill its MSME customers demands for working capital by partnering with niyogin, bringing efficient and paperless credit to its customers.
                                    </p>

                                </div>
                                <div className='div_on_hover_close'>
                                    <img src={close_vector} alt="" />
                                </div>
                            </div>
                        </li>
                        <li onClick={(e) => handhoverclick(e)}>
                            <p>KARMALIFE</p>
                            <div className='div_on_hover'>
                                <div>
                                    <img src={karmalife_logo} alt="" />
                                    <p>
                                        Karmalife facilitates workers to borrow against accrued wages - a unique credit product that provides liquidity on demand for lakhs of customers across the country.
                                    </p>

                                    <div className='div_on_hover_close'>
                                        <img src={close_vector} alt="" />
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
