import React, { useState, useEffect, useCallback } from "react";
import nextIcon from "../assets/images/nextIcon.png";
import prevIcon from "../assets/images/prevIcon.png";
import { DEVELOPMENT_WORDPRESS_DOMAIN } from "../utils/domain";

const BlogContent = ({ onBlogSelection }) => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const blogsPerPage = 9;

  const fetchBlogs = useCallback(async (page) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${DEVELOPMENT_WORDPRESS_DOMAIN}/wp-json/wp/v2/posts?page=${page}&per_page=${blogsPerPage}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const text = await response.text();
      if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
        const data = JSON.parse(text); 
        setBlogs(data);
      } else {
      const cleanedText = text.replace(/<[^>]*>/g, ''); 
      const jsonStartIndex = cleanedText.indexOf('[');
      const jsonString = cleanedText.slice(jsonStartIndex).trim();
      const data = JSON.parse(jsonString); 
        setBlogs(data);
      }
      setTotalPages(16); 
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [blogsPerPage]);

  useEffect(() => {
    fetchBlogs(currentPage);
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
  }, [currentPage, fetchBlogs]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages && !loading) {
      setCurrentPage(pageNumber);
    }
  };

  const handleReadMore = (blogId) => {
    onBlogSelection(blogId); 
  };

  return (
    <div className="blogcontent-wrapper">
      <h1 className="blogs-title">Blogs</h1>

      {loading ? (
        <div className="loader"></div>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : (
        <div className="blogcontent-container">
          {blogs.map((blog) => {
            const imageUrl = blog.yoast_head_json?.og_image?.[0]?.url;

            return (
              <div key={blog.slug} className="blog-card">
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={blog.title.rendered}
                    className="blog-card-image"
                  />
                )}
                <div className="blog-content">
                  <div className="blog-card-content">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: blog.title.rendered,
                      }}
                    />
                  </div>
                  <div className="blog-card-footer">
                    <button
                      onClick={() => handleReadMore(blog.slug)} 
                      className="blog-card-readmore"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {totalPages > 1 && (
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="prev-page"
            disabled={currentPage === 1 || loading}
          >
            <img src={prevIcon} alt="Previous" />
          </button>
          <span className="current-page">{currentPage}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="next-page"
            disabled={currentPage === totalPages || loading}
          >
            <img src={nextIcon} alt="Next" />
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogContent;
