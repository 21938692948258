import React from 'react'
import dis_img1 from "../assets/images/distribution_banner.png"

export default function DisBannerwithText() {
  return (
    <div className='DisBannerwithText_main_cont'>
        <div className='distribution_img'>
            <div className='image_distribution'>
                <img src={dis_img1} alt="" />
            </div>
            <div className='Dis_main_title'>
                <p>DISTRIBUTION NETWORK</p>
            </div>
        </div>
        <div className='distribution_ban_text' >
            <h2 data-aos="fade-up" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <span>
                REACHING OVER 1 MILLION
                MSMES THROUGH OUR
                </span>&nbsp;
                DISTRIBUTION CHANNEL</h2>
            <p data-aos="fade-down" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000">
                Niyogin offers multiple financial solutions designed specifically to cater to the needs of 
                MSMEs through our cutting-edge platform. Our extensive network comprises of 
                Financial Experts, Practising professionals, Financial Advisors and Consultants, ensuring 
                that MSMEs receive unparalleled support.

            </p>
            <p data-aos="fade-down" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000">
                Equipped with an NBFC license, we offer unsecured business loans and other working 
                capital loans to MSMEs. Our strategic partnerships with leading institutional entities 
                allow us to offer a wide array of financial products and services, such as insurance and 
                wealth management solutions, catering to the diverse needs of small businesses.
            </p>
        </div>
    </div>
  )
}
