import React, { useEffect } from "react";
import TermsImage from "../assets/images/TermsImage.png";
const TermsAndConditions = () => {
  const Section = ({ title, description }) => (
    <div className="section"  data-aos="fade-right"
    data-aos-easing="ease-in-sine"
    data-aos-duration="1000">
      <p className="section-title">{title}</p>
      <div
        className="section-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );

  // Data for sections
  const termsData = [
    {
      title: "General",
      description: `
        <ol>
          <li>The domain name www.niyogin.in or any other domains belong to subsidiary companies, holding companies, and their affiliates (hereafter referred to as “Niyogin”) and is owned by Niyogin Fintech Limited, a non-banking financial company registered under the laws of India with its registered office situated at Neelkanth Corporate IT Park, 311/312, 3rd Floor, Kirol Road, Vidyavihar, Mumbai – 400086 India (hereafter referred to as “Niyogin”).</li>
          <li>For the purpose of these Terms of Use, wherever the context so requires 'You', 'Your', or 'User' shall mean any natural or legal person who has agreed to become a registered User on the Website by providing registration data using the computer systems. Niyogin allows the User to surf the Website without registering on the Website. The term 'We', 'Us', 'Our' shall mean Niyogin Fintech Limited and includes all its successors, assignees, or affiliates.</li>
          <li>Your use of the Website and services are governed by the following terms and conditions ("Terms of Use") as applicable to the Website including the applicable policies which are incorporated herein by way of reference. If You transact on the Website, You shall be subject to the policies that are applicable to the Website for such transaction. By mere use of the Website, you shall be contracting with Niyogin and these terms and conditions Including the policies constitute Your binding obligations, with Niyogin.</li>
          <li>The Terms of Use applies to Your use of the software, Website, smart phone applications or other electronic means ("Platform").</li>
          <li>This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and Terms of Use for access or usage of the Website. To the extent applicable, these Terms of Use may be treated as one of the Finance Documents. For the purpose of this Terms of Use the term "Finance Documents" shall mean the documents executed by Niyogin with its borrowers/obligors in connection with the financial assistance provided by Niyogin</li>
          <li>We reserve the right, at Our sole discretion, to change, modify, add or remove portions of these Terms of Use at any time without any prior written notice to You. It is Your responsibility to review these Terms of Use periodically for updates/changes. Your continued use of the Website following the posting of changes will mean that You accept and agree to all revisions made from time to time. As long as You comply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the Website.</li>
          <li>ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING. By impliedly or expressly accepting these Terms of Use, You also accept and agree to be bound by Niyogin policies (including but not limited to privacy policy available at Privacy clause 3.1) as amended from time to time.</li>
        </ol>      `,
    },
    {
      title: "Membership Eligibility",
      description: `
        <p>Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to contract" within the meaning of the Indian Contract Act, 1872 including minors, un-discharged insolvents etc. are not eligible to use or challenge the Terms of Use of the Website. If you are a minor i.e. under the age of 18 years, you shall not register as a User of the Website and shall not transact on or use the Website. As a minor if you wish to use or transact on website, such use or transaction may be made by Your legal guardian or parents on the Website. Niyogin reserves the right to terminate Your membership and/or refuse to provide you with access to the Website if it is brought to Niyogin's notice or if it is discovered that you are under the age of 18 years.</p>
      `,
    },
    {
      title: "Privacy",
      description: `
       <p>Any Personal Information (as defined below) submitted in connection with Your use of the offerings or the Website is subject to Our privacy policy, located at www.niyogin.in.</p>
        `,
    },
    {
      title: "Your Account And Registration Obligations",
      description: `
        <p>When You register, the information You provide to Us during the registration process will help Us in offering content, service, and management of Your account. You are solely responsible for maintaining the confidentiality of Your account, username, and password and for all activities associated with or occurring under Your Account. You represent and warrant that Your Account information will be accurate at all times. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with the Terms of Use, We shall have the right to indefinitely suspend or terminate or block access of Your membership on the Website and refuse to provide You with access to the Website.</p>
        `,
    },
    {
      title: "Communications",
      description: `
      <ol>
        <li>When You use the Website or send emails or other data, information or communication to us or our registered agents, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically and as and when required.</li>
        <li>We may communicate with you by email or by other modes of communication, electronic or otherwise and You hereby grant consent to Niyogin either calling you on your registered Mobile Phone for the purpose of verification of information provided by you or for communicating details of any of its products, irrespective of Your mobile number being registered in the Do Not Call registry or the NCPR.</li>
      </ol>

        `,
    },
    {
      title: "CONTESTS",
      description: `
        <p>Any participant accessing the Niyogin website or its platform for participating in various contests, referral programs or media promotions (Contest(s)) organized by Niyogin on its website shall be bound by these Terms and Conditions, and all other rules, regulations and terms of use referred to herein or provided by Niyogin in relation to the same. Niyogin shall be entitled to modify these Terms and Conditions, rules, regulations and terms of use of the contests referred to herein or provided by it at its discretion as it may deem fit. Use or participation in the contests shall be construed as participant's acceptance of such Terms and Conditions, rules, regulations and terms of use referred to herein, as may be amended from time to time. Niyogin may, at its sole discretion, also notify the Participant of any change or modification in these Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Niyogin, by way of sending an email to the Participant's registered email address or posting notifications or updating the website The participant may then indicate non-acceptance of the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Niyogin by not participating in the contests or else he/she will be deemed to have accepted the modified Terms and Conditions, rules, regulations and terms of use referred to herein or provided by Niyogin.</p>
        `,
    },
    {
      title: "Term",
      description: `
      <p>
        Subject to other provisions of the privacy policy, Your profile and registration on the Platform shall be active and valid for a period of 5 (five) years from the date of such registration.</p>
      `,
    },
    {
      title: "Data Retention",
      description: `
      <ol>
        <li>Subject to the other provisions of the privacy policy, and terms and conditions, Your personal data will be retained for a duration as per the applicable law.</li>
        <li>Your data will be deleted once it is no longer needed for the specified purposes as per the applicable law.</li>
       </ol>
        `,
    },
    {
      title: "Data Breach",
      description: `
      <ol>
        <li>Subject to the other provisions of the privacy policy, and terms and conditions data breached will be handled as per the applicable law.</li>
        <li>Subject to other provisions of the privacy policy, any notice, intimation or communication to be made to Niyogin under this policy with respect to data breaches shall be reported to Mr. Noorallah Charania (the Nodal Officer of Niyogin) at info@niyogin.in or 11000-266-0266.</li>
      </ol>
        `,
    },
    {
      title: "Transaction And Communication Platform",
      description: `
      <ol>
        <li>The Website is a platform that Users utilize to interact with Niyogin for their transactions including services of granting, sanctioning facilities and other financial assistance that Niyogin is authorised to provide to the eligible borrowers (“Facilities”).</li>
        <li>In order to avail the services provided by Niyogin, You are required to register with Niyogin by logging into Your User account. During the application process, you shall be required to share and upload certain information on the Website. User data shall include Your Aadhar Card details and [personal information including but not limited to Your name, e-mail address, gender, date of birth, mobile number, passwords, photograph, mobile phone information including contact numbers, SMS, call logs and browsing history, data and login-in credentials, financial information such as bank documents, salary slips, bank statements, PAN card, bank account no., data from Credit Information Companies,] data required for Know Your Customer compliances, requirement and other relevant details (“Personal Information”). You agree that the Personal Information shall always be accurate, correct and complete. As part of the services, You authorize Us to import Your details and Personal Information dispersed over third party platforms. You understand and acknowledge that we may periodically request for updates on such Personal Information and we may receive such updated information from third party platforms.</li>
        <li>Niyogin, may allow the Users to apply for the Facilities, subject to the fulfilment of the eligibility criteria laid down by Niyogin. You understand that Niyogin may collect, authenticate, track Your location, verify and confirm the User Data, documents and details as may be required to sanction the Facilities.</li>
        <li>All transactions undertaken on Your behalf by Niyogin will be on the basis of Your express instructions/ consent and will be strictly on a non-discretionary basis. You also authorise Niyogin to get Your credit information report from one or more Credit Information Companies if required and as decided by the Company from time to time. Once you verify and upload the Personal Information and/or other documents and details on Our Platform, we shall process the same. Upon the completion of the document verification by us, the Facilities may be sanctioned at its sole discretion by Us to you, subject to fitting eligibility criteria and other conditions set forth by Us for sanctioning the Facilities. Upon receiving such documents, Niyogin may at its sole discretion disburse the loan subject to the terms and conditions of the loan agreement and other Finance Documents as per the policies. </li>
        <li>You fully understand and agree that the sanctioned Facilities may be disbursed as per the mode provided in the Platform. You are required to repay the Facilities and the outstanding amount(s) together with interest and other charges as specified in the facility agreement to Us, on the respective due date(s) mentioned in the facility agreement or other Finance Documents.</li>
        <li>In the event that You stop, cease, discontinue to use or avail the services provided by Us, through deletion or uninstallation or otherwise, till the event that Your obligations to pay the outstanding amount(s) to Niyogin exist; deletion, uninstallation, discontinuation of our Facilities, shall not release you from the responsibility, obligation and liability to repay the Facilities or outstanding amount(s) or any other charges payable to Niyogin.</li>
        <li>You understand and acknowledge that you shall be solely responsible for all the activities that occur under Your User account while availing the Facilities. You undertake that Niyogin shall not be responsible and liable for any claims, damages, and disputes arising out of use or misuse of the Facilities. By usage of the Facilities, you shall be solely responsible for maintaining the confidentiality of the User account and for all other related activities under Your User account. Niyogin reserves the right to accept or reject Your registration at its sole discretion for the services without obligation of explanation.</li>
        <li>The Personal Information provided during the registration is stored by Us for Your convenience. You are not required to log-in to Your User account, every time, to use or access the Platform. You understand and acknowledge that by accepting these Terms of Use, you authorize us to Track, fetch and use the Personal Information for the purpose of authentication and any updates with regards to Your credentials.</li>
      </ol>

        `,
    },
    {
      title: "Intellectual Property Rights",
      description: `
      <ol>
        <li>This site is controlled and operated by Niyogin. The User agrees and acknowledges that material on this site, including images and/or illustrations are protected by trademarks, copyrights and other intellectual property rights. All brand names, logos and service marks, inventions and/or any other intellectual property, including, but not limited to, the intellectual property pertaining to the services, content and technology is owned solely and exclusively by Niyogin.</li>
        <li> Nothing contained in these Terms of Use or the privacy policy should be construed as granting any license or right to use any such intellectual property.</li>
        <li>Nothing provided for as the content and/or through the services shall be construed as an assignment or any right to use other than for the limited purposes provided in these Terms of Use, if any.</li>
        <li>The User hereby agrees and acknowledges that material on Website is solely for his personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so.</li>
      </ol>
 
        `,
    },
    {
      title: "Indemnity",
      description: `
      <ol>
        <li>You shall indemnify and hold harmless Niyogin, its owner, licensee, affiliates, subsidiaries, group companies (as applicable) and their respective officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out of Your breach of this Terms of Use, privacy policy and other policies, or Your violation of any law, rules or regulations or the rights (including infringement of intellectual property rights) of a third party.</li>
        <li>You understand and acknowledge that, You are solely responsible for the capability of the electronic devices and the internet connection, you chose to run the Platform. The Platform’s operation or the services on Your electronic device is subject to availability of hardware, software specifications, internet connection and other features and specifications, required from time to time.</li>
        <li>The User hereby agrees to indemnify, keep indemnified and hold harmless Niyogin, its affiliates, directors, officers, employees, agents and other representatives from and against any losses, liabilities (contingent or otherwise), obligations, costs, charges, expenses, fines, damages, including those paid or suffered pursuant to any actions, proceedings, demands, claims, judgments, awards, and including interests and penalties with respect thereto and out-of-pocket expenses, including reasonable attorneys’ fees and disbursements suffered or incurred by Niyogin on account of the User using any intellectual property of Niyogin and/or any third party to which it has access as a result of having availed of these services, in a manner not permitted under these Terms of Use, including any of the content and understands that legal proceedings may be initiated against him/her for any such use.</li>
    </ol>

        `,
    },
    {
      title: "Warranties",
      description: `<p>Niyogin makes no warranties or representations, whether express or implied in law or residual, other than to the extent specifically contained herein, including any warranties regarding the content, services or technology provided by it and disclaims all warranties, express or implied, in connection with the content, services or technology to the fullest extent as permitted by law.</p>`,
    },
    {
      title: "Disclaimer",
      description: `
      <ol>
        <li>This Website and all content and services, included on or otherwise made available to You through this site are provided on "as is" and "as available" basis without any representation or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph, Niyogin does not warrant that:</li>
        <li>this Website will be constantly available, or available at all; or</li>
        <li>the content will be up-to-date, complete, comprehensive, accurate or applicable to Your circumstances; or</li>
       <li>the Website will meet Your requirements or will be available on an uninterrupted, timely, secure, or error-free basis; or</li>
       <li>the results that may be obtained from the use of the Website or any services offered through the website will be accurate or reliable; or</li>
       <li>any service or information obtained by You through the website will meet Your expectations; or</li>
        <li>the information on this Website is complete, true, accurate or non-misleading; or</li>
        <li>this site; information, content, product (including software) or services included on or otherwise made available to You through the Website; their servers; or electronic communication sent from Us are free of viruses or other harmful components.</li>
      </ol>
 
        `,
    },
    {
      title: "Limitation Of Liability",
      description: `
        <p>IN NO EVENT SHALL NIYOGIN BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND IN CONNECTION WITH THESE TERMS OF USE, EVEN IF USER HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES.</p>
      <ol>
        <li>Niyogin (including its officers, directors, employees, representatives, affiliates, and providers) will not be responsible or liable for (a) any injury, death, loss, claim, act of god, accident, delay, or any direct, special, exemplary, punitive, indirect, incidental or consequential damages of any kind (including without limitation lost profits or lost savings), whether based in contract, tort, strict liability or otherwise, that arise out of or is in any way connected with (i) any failure or delay (including without limitation the use of or inability to use any component of the Website), or (ii) any use of the Website or content, or (iii) the performance or non-performance by us or any provider, even if we have been advised of the possibility of damages to such parties or any other party, or (b) any damages to or viruses that may infect Your computer equipment or other property as the result of your access to the Website or Your downloading of any content from the Website.</li>
        <li>The Website may provide links to other third party websites. However, since Niyogin has no control over such third party websites, You acknowledge and agree that Niyogin is not responsible for the availability of such third party websites, and does not endorse and is not responsible or liable for any content, advertising, products or other materials on or available from such third party websites.</li>
      </ol>
        `,
    },
    {
      title: "Termination",
      description: `
        <p>If You violate the Terms of Use, Your ability to use the Platform will terminate. We may, in Our sole discretion, terminate Your access to the Platform, or any portion thereof, for any reason whatsoever without prior notice. These actions without prejudice are in addition to any other right or remedy that We may have available at law. Further, We shall not be liable to You or any third party for any such termination or discontinuance.</p>
        `,
    },
    {
      title: "Platform Use",
      description: `
      <ol>
        <li>You agree, undertake and confirm that Your use of Platform shall be strictly governed by the following binding principles:</li>
        <li>You shall not host, display, upload, modify, publish, transmit, update or share any information which:</li>
        <li>belongs to another person and to which You do not have any right to accept it without the prior consent of that person;</li>
        <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or,</li>
        <li>unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986;</li>
        <li>is misleading in any way;</li>
        <li>is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, paedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual and/or try to gain unauthorized access or exceeds the scope of authorized access to the Website or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Website or solicits passwords or personal identifying information for commercial or unlawful purposes from other users;</li>
        <li>harasses or advocates harassment of another person; involves the transmission of "junk mail", "chain letters", or unsolicited mass mailing or "spamming";</li>
        <li>infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;</li>
        <li>infringes upon or violates any third party's rights including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity;</li>
        <li>promotes an illegal or unauthorized copy of another person's copyrighted work;</li>
        <li>harm minors in any way;</li>
        <li>refers to any website or URL that, in Our sole discretion, contains material that is inappropriate for the Website or any other website, contains content that would be prohibited or violates the letter or spirit of these Terms of Use;</li>
        <li>harm minors in any way;</li>
        <li>infringes any patent, trademark, copyright or other proprietary rights or third party's trade secrets or rights of publicity or privacy or shall not be fraudulent or involve the sale of counterfeit or stolen products;</li>
        <li>deceives or misleads the addressee/ Users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
        <li>impersonate another person;</li>
        <li>contains any trojan horses, worms, time bombs, cancel bots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal information;</li>
        <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation;</li>
        <li>shall not be false, inaccurate or misleading;</li>
        <li>shall not, directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any applicable law, rule, regulation or guideline for the time being in force.</li>
        <li>shall not create liability for Us or cause Us to lose (in whole or in part) the services of Our internet service provider ("ISPs") or other suppliers;</li>
        <li>You shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any 
        portion of the Website or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Website or any content, to obtain or attempt to obtain any materials, documents or 
        information through any means not purposely made available through the Website. We reserve Our right to bar any such activity. </li>
        <li>You shall not attempt to gain unauthorized access to any portion or feature of the Website, or any other systems or networks connected to the Website or to any server, computer, network, or to any of the services offered on or through the Website, by hacking, password "mining" or any other illegitimate means.</li>
        <li>You shall not probe, scan or test the vulnerability of the Website or any network connected to the Website nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other User of or visitor to Website, or any other 
        customer, including any account on the Website not owned by You, to its source, or exploit the Website or any service or information made available or offered by or through the Website, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than Your own information, as provided for by the Website.</li>
        <li>You shall not make any negative, denigrating or defamatory statement(s) or comment(s) about Us or the brand 
        name or domain name used by Us including the terms Niyogin, or otherwise engage in any conduct or action that might tarnish the image or reputation, of Niyogin or vendors on Platform or otherwise tarnish or dilute any Niyogin 's trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by us. You agree that You will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Website or Niyogin's systems or networks, or any systems or networks connected to Niyogin.</li>
        <li>You agree not to use any device, software or routine to interfere or attempt to interfere with the proper working of the Website or any transaction being conducted on the Website, or with any other person's use of the Website.</li>
        <li>You may not forge headers or otherwise manipulate identifiers in order to disguise the origin of any message or transmittal You send to Us on or through the Website or any service offered on or through the Website. You may not pretend that You are, or that You represent, someone else, or impersonate any other individual or entity.</li>
        <li>You may not use the Website or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of Niyogin and / or others.</li>
        <li>You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules there under as applicable and as amended from time to time and also all applicable laws, rules and regulations (including, but not limited to the guidelines issued by Reserve Bank of India from time to time) regarding Your use of Our Facilities and Your enlisting for acquiring Facilities offered by Niyogin. You shall not engage in any transaction in an item or service, which is prohibited by the provisions of any applicable laws or regulations for the time being in force.</li>
        <li>Solely to enable Us to use the information You supply Us with, so that we are not violating any rights You might have in Your Information, You agree to grant Us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights You have in Your Information, in any media now known or not currently known, with respect to Your Information. We will only use Your information in accordance with the Terms of Use and privacy policy applicable to use of the Website. You may not transmit any chain letters or unsolicited commercial or junk email to other Users via the Website. It shall be a violation of these Terms of Use to use any information obtained from the Website in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person other than Us without Our prior explicit consent. In order to protect Our Users from such advertising or solicitation, We reserve the right to restrict the number of messages or emails which a user may send to other Users in any 24-hour period which We deem appropriate in our sole discretion. You understand that We have the right at all times to disclose any information (including the identity of the persons providing information or materials on the 
        Website) as necessary to satisfy any law, regulation or valid governmental request. In addition, We can (and You hereby expressly authorize Us to) disclose any information about You to law enforcement or other government officials, as we, in Our sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.</li>
        <li>We reserve the right, but have no obligation, to monitor the materials posted on the Website.</li>
        <li>Notwithstanding this right, you remain solely responsible for the content of the materials you post on the website and in your private messages. Please be advised that such content posted does not necessarily reflect Niyogin views. In no event shall Niyogin assume or have any responsibility or liability for any content posted or for any claims, damages or losses resulting from use of content and/or appearance of content on the Website.</li>
        <li>Is possible those other users (including unauthorized users or "hackers") may post or transmit offensive or obscene materials on the Website and that You may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about You due to Your use of the Website, and that the recipient may use such information to harass or injure You. Niyogin does not approve of such unauthorized uses, but by using the Website You acknowledge and agree that We are not responsible for the use of any personal information that You publicly disclose or share with others on the Website. Please carefully select the type of information that You publicly disclose or share with others on the Website.</li>
        </ol>
        `,
    },
    {
      title: "Applicable Law",
      description: `
        <p>Terms of Use shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall be exclusively in Mumbai, Maharashtra.</p>
        `,
    },
  ];

  return (
    <div className="terms-container">
      <div className="terms-header">
        <div className="terms-flex">
          <img
            src={TermsImage}
            alt="Terms and Conditions"
            data-aos="fade-left"
            data-aos-offset="10"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
            className="terms-image"
          />
          <p
            className="terms-title"
            data-aos="fade-right"
            data-aos-easing="ease-in-sine"
            data-aos-duration="1000"
          >
            TERMS AND <br />
            CONDITIONS
          </p>
        </div>
      </div>
      <div className="terms-content">
        {termsData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            description={section.description}
          />
        ))}
      </div>
    </div>
  );
};
export default TermsAndConditions;
