import React from "react";
import MediaResources_image from "../assets/images/MediaResources_image.png";

const MediaResources = () => {
  return (
    <div className="media-resources-card">
      <img
        src={MediaResources_image}
        alt="Media Resources"
        className="media-resources-image"
        data-aos="fade-right"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1500"
      />
      <div>
        <p
          className="media-resources-heading"
          data-aos="fade-left"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1500"
        >
          MEDIA
        </p>
      </div>
    </div>
  );
};

export default MediaResources;
