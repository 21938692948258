import React from 'react'

export default function CareerVacancies() {
  return (
    <div className='CareerVacancies_main_cont'>
        <div className='career_vac_tit'>
            <h2>VACANCIES</h2>
        </div>

        <div className='career_table_div'  data-aos="fade-down" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000" >
            <div className='titel_table'>
                <div>
                    <p>DESIGNATION</p>
                </div>
                <div><p>LOCATION</p></div>
            </div>
            <div className='titel_table rows_tb'>
                <div>
                    <p>Area Sales Manager </p>
                </div>
                <div><p>Chandigarh</p></div>
            </div>
            <div className='titel_table rows_tb'>
                <div>
                    <p>Sales Manager </p>
                </div>
                <div><p>Pune, Delhi, Mumbai, Ahmedabad, Udaipur, Kolkata</p></div>
            </div>

        </div>
    </div>
  )
}
