import React from 'react'
import the_economic from "../assets/images/the_eco.png"
import SME_times from "../assets/images/SME_times.png"
import the_Hindu from "../assets/images/the_hindu.png"

export default function Niyoginnews() {
  return (
    <div className='Niyogin_news_main_cont'>
        
        <div className='niyogin_text_and_imgs'>
            <div className='niyo_text'  data-aos="fade-down" 
     data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <p className='niyo_text_p_tit'>
                NIYOGIN IN NEWS 
                </p>
            </div>
            <div className='niyo_news_all_img'  data-aos="fade-up" 
     data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <div className='niyo_news_img_div'><img src={the_economic} alt="" /></div>
                <div className='niyo_news_img_div'><img src={SME_times} alt="" /></div>
                <div className='niyo_news_img_div'><img src={the_Hindu} alt="" /></div>
            </div>
        </div>
    </div>
  )
}
