import React from 'react'

export default function InvestorDarkBlue() {
  return (
    <div className='InvestorDarkBlue_main_p_sec'>
        <p>
        Registered Office: MIG 944, Ground Floor, TNHB Colony,1st Main Road, Velachery,Chennai,Tamil Nadu- 600042
        Tel No: 044 47210437   Email ID: info@niyogin.in   CIN: L65910TN1988PLC131102
        </p>
        <p>
        Share Transfer Agent: Link Intime India Private Limited C 101, 247 Park, LBS Marg, Vikhroli (west), Mumbai – 400083
        Tel No: 1800 1020 878   Fax: 022 49186060   Email ID: rnt.hekpdesk@linkintime.co.in
        </p>
    </div>
  )
}
