import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import amit_rajpal_img from "../assets/images/amit_raj_img.png"
import Gaurav_img from "../assets/images/gaurav_img.png"
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import tashwindar_img from "../assets/images/TASHWINDER SINGH.jpg"
import abhishekh_img from "../assets/images/ABHISHEK THAKKAR-min.jpg"
import noorallah_img from "../assets/images/NOORALLAH CHARANIA-min.jpg"
import debiprasad_img from "../assets/images/Debiprasad_sarangi.png"
import mohit_gang_img from "../assets/images/Mohit_Gang.jpg"
import Neha_img from "../assets/images/NEHA AGARWAL-min.jpg"
import hitesh_jain_img from "../assets/images/HITESH JAIN-min.jpg"
import sanket_img from "../assets/images/SANKET SHENDURE-min.jpg"
import devanand_img from "../assets/images/DEVANAND CHAUDHARY-min.jpg"
import gaurav_img from "../assets/images/GAURAV AGARWAL-min.jpg"
import trivenika_img from "../assets/images/TRIVENIKA AVASTHI-min.jpg"
import subhasree_img from "../assets/images/Subhasri_Sriram.png"
import kapil_img from "../assets/images/Kapil_Kapoor.jpg"
import akash_img from "../assets/images/akash_sethi.jpg"
import katarina from "../assets/images/KATARINA_ RACEK jpg.jpg"
import samir_pandiri from "../assets/images/SAMIR PANDIRI .jpg"
import sudip_vatsal_thakor from "../assets/images/SUDIP THAKOR.jpg"
import cross_close_btn from "../assets/images/X.png"
import { DEVELOPMENT_WORDPRESS_DOMAIN } from '../utils/domain';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        class='tabs_data_leader'
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      
    };
  }
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: '#1D234B',
    // border: '2px solid #000',
    color: "white",
    boxShadow: 24,
    p: 4,
    
  };


export default function  Leadershipteam() {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [data,setData] = useState(null);
    const [model,setModel] = useState(null);
    const handleOpen = (ind) => {
      setOpen(true);
      setModel(ind);
      console.log(" model clg------ ",open,ind)
    }
    const handleClose = () => {setOpen(false);
      console.log("model close ")
    }
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    useEffect(() => {
      console.log(" model ------ ",open);

       fetch(`${DEVELOPMENT_WORDPRESS_DOMAIN}/wp-json/wp/v2/pages?slug=about`)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.text(); 
            })
            .then(async (text) => {

              console.log(JSON.parse(text)[0].acf.team_members,"TEXT NIKHIL");
             setData(JSON.parse(text)[0].acf.team_members); 

            })
            .catch((error) => {
              // setError(error); // Handle any errors
              // setLoading(false);
            });
    }, [])

    console.log(data,"DATA NIKHIL")
    // {data?.map((row)=>{
    //   console.log(row,"row")
    // })}
    
  return (
    <div className='Leadership_team_main_cont' id="our_Team">
        <div className='main_leadership_title'>
            <h1>
            LEADERSHIP TEAM
            </h1>
        </div>
        <div className='leader_team_box_con'>
        <Box sx={{ width: '100%' }} >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab_class'>
          <Tab label="FOUNDERS" {...a11yProps(0)} />
          <Tab label="MANAGEMENT" {...a11yProps(2)} />
          <Tab label="BOARD OF DIRECTORS" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <div>

      {open?<><Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>AMIT RAJPAL</h1>
                    <h5>
                      NON-EXECUTIVE CHAIRMAN, CO-FOUNDER
                    </h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p>
                    Amit is Non-Executive Chairman & Co Founder at niyogin and an expert in the area of financial services investing. He believes that the best business models are built by effectively leveraging under-utilized resources to add value to under-serviced customers to achieve financial inclusion. This is in part, what drove him to start niyogin.
                    He is currently CEO of Marshall Wace Asia based out of Hong Kong and manages the Global Financial Services investing for the firm. Marshall Wace is one of the largest hedge fund managers globally in the equities space. Prior to this, Amit worked at Morgan Stanley as part of the FIG research group, leading the consistently highly ranked Asian franchise and was also responsible for leading the Global team –his talent was recognized early by the firm as he was promoted to Managing Director (MD) at the age of 30, one of the youngest MD’s in the history of the firm.
                    Amit completed his MBA (equivalent) from IIM Calcutta. He is also a qualified Cost and Works Accountant (ICWA) and completed his B.Com from University of Mumbai
                  </p>
                </Typography> */}
                {/* <Typography>
                  <h1>{model.title}</h1>
                  <h5>
                    {model.designation}
                  </h5>
                </Typography>
                <Typography  dangerouslySetInnerHTML={{
                        __html:model.about ,
                      }}>
                </Typography> */}

                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>{model.title}</h1>
                    <h5>
                      {model.designation}
                    </h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p dangerouslySetInnerHTML={{
                    __html:model.about
                  }}>
                  </p>
                </Typography>
              </Box>
            </Modal></> :<></>}
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        {/* {Index === 1 ?<><Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>AMIT RAJPAL</h1>
                    <h5>
                      NON-EXECUTIVE CHAIRMAN, CO-FOUNDER
                    </h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p>
                    Amit is Non-Executive Chairman & Co Founder at niyogin and an expert in the area of financial services investing. He believes that the best business models are built by effectively leveraging under-utilized resources to add value to under-serviced customers to achieve financial inclusion. This is in part, what drove him to start niyogin.
                    He is currently CEO of Marshall Wace Asia based out of Hong Kong and manages the Global Financial Services investing for the firm. Marshall Wace is one of the largest hedge fund managers globally in the equities space. Prior to this, Amit worked at Morgan Stanley as part of the FIG research group, leading the consistently highly ranked Asian franchise and was also responsible for leading the Global team –his talent was recognized early by the firm as he was promoted to Managing Director (MD) at the age of 30, one of the youngest MD’s in the history of the firm.
                    Amit completed his MBA (equivalent) from IIM Calcutta. He is also a qualified Cost and Works Accountant (ICWA) and completed his B.Com from University of Mumbai
                  </p>
                </Typography>
              </Box>
            </Modal></> :<></>}
            
            {Index === 2 ?<>
              <Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>GAURAV PATANKAR  </h1>
                    <h5>NON-EXECUTIVE DIRECTOR, CO-FOUNDER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <p>
                  Mr. Gaurav Patankar, Co-Founder and Non-Executive Director of Niyogin, is an institutional investor and impact entrepreneur focused on emerging markets and alternatives. He is a firm believer that the democratization and digitization of the Indian SME sector is the single biggest transformational opportunity within the Indian markets.
                  <br /><br />
                  Over 27 years of his career, he has led investment and research teams at large institutional platforms such as Bloomberg, Bank of America, BNY Mellon, Lockheed Martin, Citi, Millennium Partners and M&T Bank.
                  <br /><br />
                  Mr. Patankar holds a Ph.D. in Social and Political Sciences, an M.B.A. in Finance and Strategy, and a Bachelor’s degree in Electronics and Telecommunications Engineering.
                  </p>
                </Typography>
              </Box>
            </Modal>
            </>:<></>} */}
            {/* {Index === 3 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>TASHWINDER SINGH</h1>
                    <h5>CEO & MD</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                    Mr.  Tashwinder Singh comes with more than 30 years of leadership experience in both strategic and operational roles with significant background in General Management, Banking, Wealth Management and Private Capital Investing. He has been associated with Citigroup and KKR in his previous roles.
                    <br /><br />
                    Mr. Singh is an accomplished team-builder with a passion for setting the corporate vision, defining and implementing future-driven strategy and growing businesses through entrepreneurial innovation and a customer centric approach. He has proven abilities across all levels of organizational management to build, manage and scale business with focus on Commercial banking, Investment banking, Private banking, Principal investing amongst others. During his long career in the Banking industry, he has built expertise in the areas of Investment Banking, deal diligence, underwriting, Principal Investing Asset Management Business in India.
                    <br /><br />
                    He holds a masters in Business Administration from Faculty of Mgmt. Studies (Delhi University) and BE (Electrical) from Delhi College of Engineering (Delhi University).

                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 4 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>ABHISHEK THAKKAR</h1>
                    <h5>CHIEF FINANCIAL OFFICER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Mr. Abhishek Thakkar is a management professional with 12+ years of experience in the field of Finance, Accounting, Regulatory/Group Reporting and Operations. He has been associated with Avendus Capital Private Limited, Aegis Logistics Limited and Deloitte Haskins & Sells LLP in his previous roles, where he has been instrumental in closing Mergers & Acquisitions, Ind AS implementation, Structured Finance deals etc.
                      <br /><br />
                      He is a qualified Chartered Accountant and holds Diploma in IFRS from the Association of Chartered Certified Accountants (ACCA) UK.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 5 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>AKASH SETHI</h1>
                    <h5>CHIEF OPERATING OFFICER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                    Akash Sethi serves as the Chief Operating Officer. He is responsible for overseeing the company’s key innovative initiatives to ensure long-term growth and success. Mr. Sethi brings over a decade of experience to his role. He has played a pivotal role at Fincent Software Services, assisting the Founder’s Office. As CEO and co-founder of The Sweetish House Mafia, he successfully leveraged marketing and other transformative strategies to elevate a food truck venture into a thriving pan-India chain.
                      <br /><br />
                      He holds an MBA from the University of Massachusetts.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 6 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>NOORALLAH CHARANIA</h1>
                    <h5>CHIEF OF STAFF</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Noorallah Charania holds the position of Chief of Staff at Niyogin. What brought him here was the drive to deliver innovative and technology-based, world-class operations by flawlessly executing the business strategy. In his role here, he seeks customer excellence through trustworthy and reliable operations while proactively managing risk.
                      <br /><br />
                      He has over 26 years of experience in Operations, Internal Control Audits, Risk Management, Credit Management, Mid-office, Documentation, Treasury Management and Process Management. He has held senior positions in ABN, Aditya Birla Group, RBS and HDFC Bank. His strength lies in his understanding of the processes and transactions including operational experience in taking any transactions through all aspects of their lifecycle. He utilizes keen analysis, valuable insights and a team approach in carrying out velocity models and applying different methods of time-depth conversion.
                      <br /><br />
                      An MBA, M.com with a CAIIB from the Indian Institute of Banking and Finance, Noorallah enjoys cricket, football, music and reading in his free time.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 7 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>DEBIPRASAD SARANGI</h1>
                    <h5>CHIEF EXECUTIVE OFFICER, ISERVEU</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                    Debiprasad Sarangi, an engineer by qualification and a specialist in 
                    fintech products, Debi has worked with product teams of renowned PPI 
                    players like GI Technology, etc. He was a member of the iCash team,
                     which was a leading player in the domestic remittance and digital 
                    payment space.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 8 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>MOHIT GANG</h1>
                    <h5>CHIEF EXECUTIVE OFFICER, MONEYFRONT</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Hailing from Jodhpur, Mohit Gang is an investment expert and holds a management degree with dual specialization in Marketing and Finance from MDI, Gurgaon.
                      <br /><br />
                      His work timeline sparkles with soaring deliveries at leading banks – HSBC and Citibank. Mohit led and managed a portfolio of about $2 Bn in assets driving a network of over 240 Relationship Managers at HSBC. While at Citibank, he took ownership of an annual target of $ 9 million revenues handling multiple assets like Mutual Funds, PMS, Alternates, Bonds etc. Mohit has personally handled HNI & Ultra-HNI portfolios and successfully steered sophisticated investments of his clients during all market cycles. He has also handled large teams of Relationship Managers during his stint at Citi, and nurtured and instilled a culture of ‘client-first’ amongst his teams.
                      <br /><br />
                      At Moneyfront.in, he is building an entire gamut of wealth management offerings with already having routed INR 3,500 Cr AUM. With the sister concern, Investdirect Capital, he has empowered 4000 plus partners to offer mutual fund investments offering to their clients and network. Under his leadership, Moneyfront has also successfully pioneered analytics & reporting engines to analyze multi-product multi-advisor portfolios and provided this tool to a few of the largest private wealth platforms in the country.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 9 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>NEHA AGARWAL</h1>
                    <h5>COMPANY SECRETARY & COMPLIANCE OFFICER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Ms. Neha Agarwal is Company Secretary and Compliance officer at Niyogin where she is responsible for handling compliances under Companies Act, RBI and other regulatory compliances. She has over 7 years of experience in this fields of legal, secretarial and compliance.
                      <br /><br />
                      She is an associate member of the Institute of Company Secretaries of India. She holds a Bachelor’s degree in Law and a Bachelor’s degree in Commerce with specialization in Financial Accounting & Auditing from University of Mumbai.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 10 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>HITESH JAIN</h1>
                    <h5>CHIEF RISK OFFICER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Hitesh Jain leads the Risk function at Niyogin. He comes with 
                      over 22 years of work experience in leading corporates in the
                      Financial Services Industry.
                        <br /><br />
                      Hitesh has demonstrated the ability to work across multiple functions like 
                      Credit – Policy and underwriting, Risk Management, Digital Payment, 
                      Digital Lending & Risk Control. He has worked with renowned organizations 
                      like Kotak Mahindra, Fullerton India, Jana Small Finance Bank and EnKash. 
                      He is a qualified Chartered Accountant & holds certifications in Treasury 
                      & Forex Risk Management from ICAI & in Consumer Business Strategic Risk 
                      management.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 11 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>SANKET SHENDURE</h1>
                    <h5>CHIEF PRODUCT & GROWTH OFFICER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Sanket Shendure serves as the Chief Product and Growth Officer at 
                      Niyogin, where he excels in product development and growth strategies 
                      and strengthens embedded lending and co-lending businesses while 
                      spearheading innovative product initiatives.
                      <br /><br />
                      With a background as the Founder and CEO of Minko—app & MinksPay.com, 
                      Sanket brings a wealth of entrepreneurial experience. He has a proven 
                      track record of building high-performance products and teams, scaling 
                      operations effectively, and driving significant business growth. 
                      His success is largely attributed to his data-driven approach and 
                      strategic partnerships.
                      <br /><br />
                      Sanket is an entrepreneur at heart and enjoys traveling, trekking, 
                      swimming, and adventure sports.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 12 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>DEVANAND CHAUDHARY</h1>
                    <h5>CHIEF SALES OFFICER-RETAIL</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      As the Chief Sales Officer-Retail, Devanand is responsible for building and 
                      delivering business volumes through the retail channel ecosystem for niyogin. 
                      He is responsible for the Go To Market strategy & execution, retail channel 
                      development and customer acquisition. Devanand is a firm believer in the 
                      strength of SMEs as a key contributor towards the growth of economy & is 
                      passionate to adopt various technologies for extending best in class experience to customers and partners.
                      <br /><br />
                      Devanand brings in over 13+ years experience in SME/MSME Sales Lifecycle Planning & Execution, 
                      Strategy Development & Execution  for Retail & Enterprise Channel, He has worked with Vodafone 
                      India Limited for 10+ years in various roles & assignments,majorly in SME Segment- Mumbai Circle.
                       He was part of various new product/service launches for SME Segment.
                      <br /><br />
                      He has completed his PGDMM in Marketing Management from NMIMS and B.Com from Mumbai University. 
                      Devanand loves travelling, listening to music,reading blogs/articles,exploring & learning new technologies.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 13 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>GAURAV AGARWAL</h1>
                    <h5>PARTNERSHIPS & ALLIANCES</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Gaurav Agarwal manages Partnerships & Alliances at Niyogin; a strategic role that 
                      entails identifying and nurturing key business relationships with the intent to 
                      fuel growth and help Niyogin serve emerging MSMEs through the partner network.
                      <br /><br />
                      Gaurav brings with him 17+ years of experience with new-age banks like ICICI, 
                      Kotak & Yes Bank. He was also a part of the founding team at Zeta Suite (Neo Banking unicorn), 
                      where he worked closely with Business & Product teams to help create digital products to solve
                      practical problems faced at the workplace concerning payments and service delivery.
                      <br /><br />
                      Gaurav has pursued his PGDBM from N L Dalmia Institute of Management Studies & Research 
                      and is also a JAIIB certified Banker. In his spare time, he loves to travel and meet 
                      people from varied backgrounds.
                    </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 14 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>TRIVENIKA AVASTHI</h1>
                    <h5>INVESTOR RELATIONS OFFICER</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Trivenika has over 10 years of industry experience spanning across engineering and BFSI. She has varied experience across functions like wholesale lending, investment banking and investor relations. She has worked with organizations like L&T, Yes Bank and Equirus Capital.
                      In her previous role she raised equity for growth-stage new-age businesses. She was also part of the IR team of one of the largest FPOs in India which raised USD 2 Bn. in turnaround equity for Yes Bank.
                      She has a B.Tech in Chemical Engineering from VNIT, Nagpur and has completed her MBA from NMIMS, Mumbai.
                    </p>
                </Typography>
              </Box>
            </Modal> :""} */}
            {/* {Index === 15 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>SUBHASRI SRIRAM</h1>
                    <h5>INDEPENDENT DIRECTOR</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Ms. Subhasri Sriram has more than 3 decades of professional experience including 25 years in Shriram Group’s 
                      Financial Services business and during this period for more than 15 years held the position of Executive Director
                      & CFO of one of their listed Non-Banking Finance Companies. Has handled many critical assignments related to 
                      organisational re-structuring, business process improvement, fund raise – both equity and debt, and new business launches.
                      <br /><br />
                      Has been the winner at the CNBC TV 18, “Best Performing CFO” in 2013 and the “Most Influential CFO in India” by CIMA in 2016.
                      <br /><br />
                      She is a Postgraduate in Commerce, Fellow Member of the Institute of Cost Accountants of India (ICMA) 
                      and the Institute of Company Secretaries of India (ICSI) and is in the board of several listed and unlisted companies across industries.
                    </p>
                </Typography>
              </Box>
            </Modal> :""} */}
            {/* {Index === 16 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>KAPIL KAPOOR</h1>
                    <h5>INDEPENDENT DIRECTOR</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                      Mr. Kapil Kapoor is the founder and trustee of Ashoka University. 
                      He currently serves as the Chairman and Non-Executive Director of 
                      Info Edge (India) Limited. He started his professional career in 1987 
                      in sales and Brand management with Nestle India Ltd. He later worked 
                      with Bausch & Lomb, where he was part of the start-up team that launched 
                      RayBan Sunglasses and the Bausch & Lomb vision care range in India. He 
                      subsequently went on to manage the overseas marketing territories of 
                      Russia, Ukraine and East Africa in addition to the SAARC region. In 1996, 
                      Mr. Kapoor relocated to Thailand as the Country Manager for Bausch & Lomb
                      and Commercial Director for South East Asia and the SAARC region.
                      <br /><br />
                      Mr. Kapoor then joined Timex Group and was Chairman and Managing Director
                      of the publicly held Timex Group India Ltd. from October 2000 onwards. 
                      He also managed the Asia Pacific region for the Group from 2003 and 
                      eventually went onto become the Global Chief Operating Officer of Timex 
                      Group, USA from 2009 until 2013.
                      <br /><br />
                      Mr Kapoor has earned a degree in Economic Honours from Delhi University and
                      MBA (PGDM) from Indian Institute of Management, Ahmedabad. He has also 
                      pursued executive education programs at Ashridge University and Harvard 
                      University.
                   </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 17 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>KATARINA RACEK</h1>
                    <h5>INDEPENDENT DIRECTOR</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                    Ms. Racek has been part of Institutional Investor’s (‘II’) executive management and the senior leadership board for the past decade. In her 15 years at II, Ms. Racek has served as the Global Head of Investor Relations and has been the driving force behind the asset management capital introduction business at Institutional Investor. She is currently spearheading II’s largest business pillars across distribution, investment solutions, trading, and technology. She has recently launched The Asset Management Institute (AMI), one of Institutional Investor’s most prestigious membership organizations dedicated to serving the asset management industry.
                      <br /><br />
                      She has facilitated deep relationships with asset allocators across public and private pension plans, endowments and foundations, insurance funds, healthcare institutions, sovereign funds, OCIOs, large RIAs, family offices, and retirement plan advisors, managing a global team of investor relations professionals based out of New York, Hong Kong, and London.
                      <br /><br />
                      She holds a Master’s degree in Business Economics from the University of Economics in Bratislava and a Diploma in International Business and Finance from New York University.
                   </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 18 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>SAMIR PANDIRI</h1>
                    <h5>INDEPENDENT DIRECTOR</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                    Mr. Samir Mohan Pandiri is an experienced CEO, Board Chair, Client, Sales and Business Executive. Most recently, he was the President of Apex Group overseeing approximately 10,000 associates of all business  areas including, Revenue, Geographic Management, Operations, Human Resources,Risk and Audit and Top Tier Clients.
                      <br /><br />
                      Previously he was President of Broadridge International, leading a 2000+  person FinTech division encompassing Capital Markets, Asset Management and Reg Tech reporting solutions. Samir as CEO led BNY Mellon’s Asset Servicing division with $33Tr of assets under custody and $5.5Bn revenues, with 18,000 employees operating in over 45 countries. He has held various leadership roles in Asset Servicing, Asset Management and Fin Tech at Broadridge, BNY Mellon, JPMorgan Chase and Bankers Trust.
                      <br /><br />
                      Mr. Pandiri has held expatriate work assignments over 15 years in Hong Kong and London. He also served as a Senior Advisor to the Boston Consulting Group. In 2018, he was appointed by Governor Philip Murphy to the board of the New Jersey State Investment Council, which oversees the state’s $100Bn pension plans. Additionally, Samir served on the Advisory boards of two Fintech startups, EZOPS and Regology.
                      <br /><br />
                      Mr. Pandiri is a graduate of Columbia University with degrees in MBA (Finance), MS, and BS in Chemical Engineering.
                   </p>
                </Typography>
              </Box>
            </Modal> :""}
            {Index === 19 ?<Modal
            className='model_of_people_data'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} >
                <img src={cross_close_btn} alt={cross_close_btn} className='cros_close_btn' onClick={handleClose}/>
                
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <h1>SUDIP VATSAL THAKOR</h1>
                    <h5>INDEPENDENT DIRECTOR</h5>
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <p>
                    Mr. Thakor is a senior financial executive with over 30 years of experience in asset management and global markets.
                      <br /><br />
                      Mr. Thakor enjoys teaching a course on Financial Markets and Sustainability at Cornell University, and serves on the Advisory Council for the Dean of the College of Agriculture and Life Sciences. He mentors underserved high-school and college students through Student Sponsor Partners in NY, and Greenwich Alliance for Education in CT.
                      <br /><br />
                      Mr. Thakor invests and advises early-stage companies in the US and India and advises funds and companies focused on providing innovative capital solutions in developing markets. Previously, he was a member of the Asset Management and Fixed Income Operating Committees at Credit Suisse First Boston, where he ran various global businesses in Credit and Emerging Markets over 17 years.
                      <br /><br />
                      He holds a Bachelor of Arts degree in Economics from Cornell University and a Master of Business Administration from Columbia Business School.
                      </p>
                </Typography>
              </Box>
            </Modal> :""} */}

        </div>

        {data && data.map((row) =>(
          <CustomTabPanel value={value} index={row.tab_title === "Founders" ? 0 :row.tab_title === "Management" ? 1: row.tab_title === "Board of Directors" ? 2 :0 }>
   
          <div className='container_of_peop_data'>  
             { row.team.map((e)=>(
              <div className="peopele_data_with_img" onClick={()=>{handleOpen(e)}}>
                <div className='image_people'>
                    <img alt="leader_image" src={e.image.url}  />
                </div>
                <div className="name_title_of_people">
                  <h2>{e.title}</h2>
                  <p>
                        {/* NON-EXECUTIVE CHAIRMAN,<br /> CO-FOUNDER */}
                        {e.designation}
                  </p>
                </div>
              </div>
             ))}
              
              
          </div>
        </CustomTabPanel>
        )) }
      {/* <CustomTabPanel value={value} index={0}>
        <div className='container_of_peop_data'>  
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,1)}>
              <div className='image_people'>
                  <img alt="leader_image" src={amit_rajpal_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>AMIT RAJPAL</h2>
                <p>
                      NON-EXECUTIVE CHAIRMAN,<br /> CO-FOUNDER
                </p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,2)}>
              <div className='image_people'>
                  <img alt="leader_image" src={Gaurav_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>GAURAV PATANKAR</h2>
                <p>NON-EXECUTIVE DIRECTOR,<br /> CO-FOUNDER</p>
              </div>
            </div>
            
        </div>
        
        
        
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div className='container_of_peop_data'>  
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,3)}>
              <div className='image_people'>
                  <img alt="leader_image" src={tashwindar_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>TASHWINDER SINGH</h2>
                <p>CEO & MD</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,4)}>
              <div className='image_people'>
                  <img alt="leader_image" src={abhishekh_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>ABHISHEK THAKKAR</h2>
                <p>CHIEF FINANCIAL OFFICER</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,5)}>
              <div className='image_people'>
                  <img alt="leader_image" src={akash_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>AKASH SETHI </h2>
                <p>CHIEF OPERATING OFFICER</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,6)}>
              <div className='image_people'>
                  <img alt="leader_image" src={noorallah_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>NOORALLAH CHARANIA </h2>
                <p>CHIEF OF STAFF</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,7)}>
              <div className='image_people'>
                  <img alt="leader_image" src={debiprasad_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>DEBIPRASAD SARANGI</h2>
                <p>CHIEF EXECUTIVE OFFICER, ISERVEU</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,8)}>
              <div className='image_people'>
                  <img alt="leader_image" src={mohit_gang_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>MOHIT GANG</h2>
                <p>CHIEF EXECUTIVE OFFICER, MONEYFRONT</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,9)}>
              <div className='image_people'>
                  <img alt="leader_image" src={Neha_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>NEHA AGARWAL</h2>
                <p>COMPANY SECRETARY & COMPLIANCE OFFICER</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,10)}>
              <div className='image_people'>
                  <img alt="leader_image" src={hitesh_jain_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>HITESH JAIN</h2>
                <p>CHIEF RISK OFFICER</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,11)}>
              <div className='image_people'>
                  <img alt="leader_image" src={sanket_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>SANKET SHENDURE</h2>
                <p>CHIEF PRODUCT & GROWTH OFFICER</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,12)}>
              <div className='image_people'>
                  <img alt="leader_image" src={devanand_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>DEVANAND CHAUDHARY  </h2>
                <p>CHIEF SALES OFFICER-RETAIL</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,13)}>
              <div className='image_people'>
                  <img alt="leader_image" src={gaurav_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>GAURAV AGARWAL</h2>
                <p>PARTNERSHIPS & ALLIANCES</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,14)}>
              <div className='image_people'>
                  <img alt="leader_image" src={trivenika_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>TRIVENIKA AVASTHI</h2>
                <p>INVESTOR RELATIONS OFFICER</p>
              </div>
            </div>
            
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <div className='container_of_peop_data'>  
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,1)}>
              <div className='image_people'>
                  <img alt="leader_image" src={amit_rajpal_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>AMIT RAJPAL</h2>
                <p>
                      NON-EXECUTIVE CHAIRMAN, <br />CO-FOUNDER
                    </p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,2)}>
              <div className='image_people'>
                  <img alt="leader_image" src={Gaurav_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>GAURAV PATANKAR</h2>
                <p>NON-EXECUTIVE DIRECTOR,<br /> CO-FOUNDER</p>
              </div>
            </div>
            {/* <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,15)}>
              <div className='image_people'>
                  <img alt="leader_image" src={subhasree_img}  />
              </div> 
              <div className="name_title_of_people">
                <h2>SUBHASRI SRIRAM</h2>
                <p>INDEPENDENT DIRECTOR</p>
              </div> 
            </div> */}
            {/* <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,16)}>
              <div className='image_people'>
                  <img alt="leader_image" src={kapil_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>KAPIL KAPOOR </h2>
                <p>INDEPENDENT DIRECTOR</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,17)}>
              <div className='image_people'>
                  <img alt="leader_image" src={katarina}  />
              </div>
              <div className="name_title_of_people">
                <h2>KATARINA RACEK </h2>
                <p>INDEPENDENT DIRECTOR</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,3)}>
              <div className='image_people'>
                  <img alt="leader_image" src={tashwindar_img}  />
              </div>
              <div className="name_title_of_people">
                <h2>TASHWINDER SINGH</h2>
                <p>CEO & MD</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,18)}>
              <div className='image_people'>
                  <img alt="leader_image" src={samir_pandiri}  />
              </div>
              <div className="name_title_of_people">
              <h2>SAMIR PANDIRI</h2>
              <p>INDEPENDENT DIRECTOR</p>
              </div>
            </div>
            <div className="peopele_data_with_img" onClick={(e,ind)=>handleOpen(e,19)}>
              <div className='image_people'>
                  <img alt="leader_image" src={sudip_vatsal_thakor}  />
              </div>
              <div className="name_title_of_people">
              <h2>SUDIP VATSAL THAKOR </h2>
              <p>INDEPENDENT DIRECTOR</p>
              </div>
            </div>
        </div>
      </CustomTabPanel> */} 
    </Box>
        </div>
    </div>
  )
}
