import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlogContent from '../components/BlogContent';

const Blogs = () => {
  const navigate = useNavigate();

  const handleBlogSelection = (blogId) => {
    navigate(`/blogs/${blogId}`);
  };

  return (
    <div>
      <BlogContent
        onBlogSelection={handleBlogSelection}
      />
    </div>
  );
};

export default Blogs;
