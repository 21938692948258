import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[500],
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 16px",
  },
  "& .MuiInputLabel-root": {
    fontSize: "14px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: "12px",
  },
}));

const FormContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0px",
  gap: "16px",
  maxWidth: "1250px",
  margin: "0 auto",
});

const FormButton = styled(Button)({
  width: "100%",
  backgroundColor: "blue",
  color: "white",
  "&:hover": {
    backgroundColor: "darkblue",
  },
});

const FormField = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  width: "100%",
});

const FormIcon = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
});

const FormInput = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  width: "100%",
});

function CareerFormMui() {
  const [formState, setFormState] = useState({
    name: "",
    file: null,
    errors: {},
    submissionMessage: "",
  });

  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    company: "",
    phone: "",
    email: "",
    position: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    const nameRegex = /^[A-Za-z\s]*$/; // Only letters and spaces
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const phoneRegex = /^\d{0,10}$/;
    const phoneRegex = /^\d{10}$/;     

    // Validate input on change for first name
    if (name === "firstname") {
      if (nameRegex.test(value) || value === "") {
        setFormData((prev) => ({ ...prev, firstname: value }));
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, firstname: undefined },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            firstname: "First name can only contain letters and spaces.",
          },
        }));
      }
    }

    // Validate input on change for last name
    if (name === "lastname") {
      if (nameRegex.test(value) || value === "") {
        setFormData((prev) => ({ ...prev, lastname: value }));
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, lastname: undefined },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            lastname: "Last name can only contain letters and spaces.",
          },
        }));
      }
    }

    if (name === "city") {
      if (nameRegex.test(value) || value === "") {
        setFormData((prev) => ({ ...prev, city: value }));
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, city: undefined },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: {
            ...prev.errors,
            city: "City can only contain letters and spaces.",
          },
        }));
      }
    }

    if (name === "email") {
      setFormData((prev) => ({ ...prev, email: value }));
      if (emailRegex.test(value) || value === "") {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, email: undefined },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, email: "Email format is invalid." },
        }));
      }
    }

    if (name === "phone") {
      const numericValue = value.replace(/\D/g, ''); // Remove non-digit characters
      setFormData((prev) => ({ ...prev, phone: numericValue }));
  
      // Validate length and content
      if (numericValue.length === 0) {
        // No input
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, phone: undefined },
        }));
      } else if (numericValue.length < 10) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, phone: "Phone number must be exactly 10 digits." },
        }));
      } else if (numericValue.length > 10) {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, phone: "Phone number must not exceed 10 digits." },
        }));
      } else if (phoneRegex.test(numericValue)) {
        // Only checks if it's exactly 10 digits
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, phone: undefined },
        }));
      } else {
        setFormState((prev) => ({
          ...prev,
          errors: { ...prev.errors, phone: "Phone number must be exactly 10 digits." },
        }));
      }
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};

    // Validate form fields
    if (!formData.firstname) newErrors.firstname = "First name is required.";
    if (!formData.lastname) newErrors.lastname = "Last name is required.";
    if (!formData.phone) newErrors.phone = "Phone number is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.city) newErrors.city = "City is required.";
    if (!formState.file) newErrors.resume = "Resume file is required.";

    // If there are validation errors, update the state and return
    if (Object.keys(newErrors).length > 0) {
      setFormState((prev) => ({
        ...prev,
        errors: newErrors,
        submissionMessage: "",
      }));
      return;
    }

    // Read the file if it exists
    if (formState.file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result;

        const applicationData = {
          firstName: formData.firstname,
          lastName: formData.lastname,
          phoneNumber: formData.phone,
          email: formData.email,
          city: formData.city,
          position: "",
          document: base64Data,
        };

        console.log("Application data:", applicationData);

        try {
          const response = await fetch(
            "https://soa.niyogin.in/gates/1.0/sweeps/generic/applyForJob",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Basic QXBxcXg6QjFRLVZaVmkt",
              },
              body: JSON.stringify(applicationData),
            }
          );

          if (!response.ok) {
            throw new Error(
              `Network response was not ok: ${response.statusText}`
            );
          }

          const data = await response.json();
          console.log("Form submitted successfully:", data);

          // Reset form fields
          setFormData({
            firstname: "",
            lastname: "",
            phone: "",
            email: "",
            city: "",
          });
          setFormState({
            name: "",
            file: null,
            errors: {},
            submissionMessage: "Form submitted successfully!",
          });
        } catch (error) {
          console.error("Error submitting form:", error);
          setFormState((prev) => ({
            ...prev,
            submissionMessage: "Error submitting form. Please try again.",
          }));
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        setFormState((prev) => ({
          ...prev,
          submissionMessage: "Error reading file. Please try again.",
        }));
      };
      reader.readAsDataURL(formState.file);
    } else {
      console.log("No resume file selected");
      setFormState((prev) => ({
        ...prev,
        submissionMessage: "Please upload your resume.",
      }));
    }
  };

  return (
    <FormContainer className="form_Career_cont">
      <form onSubmit={handleSubmit}>
        <FormField>
          {/* <FormIcon>
            <PersonIcon />
          </FormIcon> */}
          <FormInput className="input_style_cl">
            <StyledTextField
              id="firstname"
              name="firstname"
              label="First Name:"
              variant="outlined"
              fullWidth={true}
              value={formData.firstname}
              onChange={handleChange}
              error={!!formState.errors.firstname}
              helperText={formState.errors.firstname}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <WorkIcon />
          </FormIcon> */}
          <FormInput className="input_style_cl">
            <StyledTextField
              id="lastname"
              name="lastname"
              label="Last Name:"
              variant="outlined"
              fullWidth={true}
              value={formData.lastname}
              onChange={handleChange}
              error={!!formState.errors.lastname}
              helperText={formState.errors.lastname}
            />
          </FormInput>
        </FormField>
        <FormField>
          {/* <FormIcon>
            <positionOnIcon />
          </FormIcon> */}
          <FormInput className="input_style_cl">
            <StyledTextField
              id="city"
              name="city"
              label="City:"
              variant="outlined"
              fullWidth={true}
              value={formData.city}
              onChange={handleChange}
              error={!!formState.errors.city}
              helperText={formState.errors.city}
            />
          </FormInput>
        </FormField>
        <FormField className="email_div">
          {/* <FormIcon>
            <EmailIcon />
          </FormIcon> */}
          <FormInput className="input_style_cl">
            <StyledTextField
              id="email"
              name="email"
              label="Email:"
              variant="outlined"
              fullWidth={true}
              value={formData.email}
              onChange={handleChange}
              error={!!formState.errors.email}
              helperText={formState.errors.email}
            />
          </FormInput>
        </FormField>

        <FormField className="phone_div">
          {/* <FormIcon>
            <PhoneIcon />
          </FormIcon> */}
          <FormInput className="input_style_cl">
            <StyledTextField
              id="phone"
              name="phone"
              label="Mobile Number:"
              variant="outlined"
              fullWidth={true}
              value={formData.phone}
              onChange={handleChange}
              error={!!formState.errors.phone}
              helperText={formState.errors.phone}
            />
          </FormInput>
        </FormField>

        <FormField className="file_upload_div">
          {/* <FormIcon>
            <PhoneIcon />
          </FormIcon> */}
          <FormInput className="input_style_cl">
            <h1>RESUME {formState.name ? formState.name : "Choose a file:"}</h1>
            <Button
              className="btn_file_upload"
              component="label"
              role={undefined}
              variant="contained"
              tabIndex={-1}
              // startIcon={<CloudUploadIcon />}
            >
              Browse
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file) {
                    setFormState((prev) => ({
                      ...prev,
                      name: file.name,
                      file: file,
                    }));
                  }
                }}
                accept=".pdf,.doc,.docx"
              />
            </Button>
          </FormInput>
        </FormField>


        <div className="btn_container">
          {formState.submissionMessage && (
            <div
              style={{
                color:
                  formState.errors && Object.keys(formState.errors).length > 0
                    ? "red"
                    : "green",
                    marginBottom: "16px", 
              }}
            >
              {formState.submissionMessage}
            </div>
          )}

          <FormButton
            className="call_back_btn"
            type="submit"
            variant="contained"
          >
           Submit
          </FormButton>


        </div>
      </form>
    </FormContainer>
  );
}

export default CareerFormMui;
