import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types';
import anime from 'animejs';
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CareerFormMui from './CareerformMui';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className='dat_tabs'
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#1D234B',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    padding:"0",
  };


export default function CareerTabs() {
    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    const svgRef2 = useRef(null);
    // const svgRef2 = useRef(null);
  useEffect(() => {
    // const svg1 = svgRef1.current;
    const svg2 = svgRef2.current;

    // const paths1 = svgRef1.current.querySelectorAll('path');
    const paths2 = svgRef2.current.querySelectorAll('path');
    
    
    anime({
        targets: paths2,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: 'easeInOutSine',
        duration: 5000,
        direction: 'normal'
        // loop: true,

      });

    })

  return (
    <div className='careers_tabs_main'>
         <div className='leader_team_box_con careers_tabs'data-aos="fade-up" data-aos-offset="100"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000"  >
        <Box sx={{ width: '100%' }} >
        <Box sx={{ borderBottom: 1, borderColor: 'divider',position:"relative" }} >
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab_class'>
            <Tab label="AREA SALES MANAGER" {...a11yProps(0)}  />
            <Tab label="SALES MANAGER – SALES" {...a11yProps(1)}/>
            </Tabs>
            <div className='svg_animation1 new_svg'>
                <svg width="100%" ref={svgRef2} id="my_svg_element" height="100%" viewBox="0 0 1425 885" fill="none" xmlns="http://www.w3.org/2000/svg" class=" drawsvg-initialized " >
                            <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1425" height="885">
                            <rect width="1425" height="885" fill="#C4C4C4"></rect>
                            </mask>
                            <g mask="url(#mask0)">
                            <path d="M1234.72 1197.88C1234.72 1197.88 1252.8 647.228 685.93 636.622C607.34 635.135 58.4301 618.807 -395.928 605.028" stroke="#24A0D0" stroke-opacity="0.2" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1276.39 1137.72C1276.39 1137.72 1249.57 635.389 704.334 625.201C628.193 623.771 65.1712 624.567 -396.711 625.544" stroke="#24A0D0" stroke-opacity="0.3" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1317.95 1077.56C1317.95 1077.56 1246.12 623.458 722.766 613.651C649.074 612.278 71.9185 630.757 -397.627 646.509" stroke="#24A0D0" stroke-opacity="0.4" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1359.63 1017.41C1359.63 1017.41 1242.89 611.64 741.308 602.232C670.181 600.919 78.7543 637.66 -398.342 668.275" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1401.31 957.252C1401.31 957.252 1239.55 599.711 759.832 590.684C691.27 589.431 85.4117 644.99 -399.194 690.599" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1442.87 897.093C1442.87 897.093 1236.24 587.762 778.259 579.263C712.376 578.072 91.8623 652.897 -400.077 713.74" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1484.55 836.937C1484.55 836.937 1232.88 575.941 796.806 567.716C733.604 566.587 98.392 661.151 -400.973 737.205" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1526.11 776.779C1526.11 776.779 1229.56 564.013 815.21 556.294C754.83 555.124 104.784 669.983 -401.9 761.488" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1567.79 716.602C1567.79 716.602 1226.22 552.171 833.753 544.855C776.192 543.75 111.156 679.353 -402.843 786.201" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1609.46 656.447C1609.46 656.447 1223 540.224 852.301 533.307C797.419 532.265 117.371 689.194 -403.808 811.496" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1651.03 596.288C1651.03 596.288 1219.55 528.293 870.71 521.756C818.763 520.783 123.453 699.485 -404.791 837.242" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1692.7 536.133C1692.7 536.133 1216.32 516.475 889.137 510.335C840.239 509.433 129.541 710.227 -405.8 863.7" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1734.38 475.977C1734.38 475.977 1212.99 504.546 907.657 498.895C861.694 498.06 135.584 721.55 -406.845 890.457" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1775.94 415.818C1775.94 415.818 1209.64 492.726 926.087 487.366C883.175 486.581 141.387 733.084 -407.862 917.711" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1817.62 355.663C1817.62 355.663 1206.31 480.776 944.631 475.926C904.79 475.235 147.284 745.201 -408.927 945.61" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1859.18 295.504C1859.18 295.504 1202.97 468.955 963.039 464.397C926.368 463.65 152.909 757.764 -410.002 973.768" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1900.86 235.349C1900.86 235.349 1199.63 457.027 981.581 452.957C947.961 452.281 158.66 770.675 -411.094 1002.38" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1942.42 175.169C1942.42 175.169 1196.29 445.056 1000.01 441.407C969.697 440.808 164.251 784.143 -412.207 1031.55" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1984.1 115.013C1984.1 115.013 1192.95 433.235 1018.56 429.967C991.404 429.464 169.737 797.953 -413.333 1061.06" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1978.63 75.3254C1799.92 152.368 1167.73 420.925 1037.08 418.44C1013.25 417.992 175.184 812.127 -414.358 1090.9" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1859.79 90.4618C1590.81 212.846 1145.58 408.667 1055.51 407.02C1034.96 406.649 180.496 826.86 -415.63 1121.23" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1737.49 115.88C1466.63 245.395 1136.37 396.688 1074.03 395.472C1056.79 395.178 185.661 841.783 -416.822 1151.86" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1635.94 138.959C1388.04 263.407 1135.12 384.679 1092.46 383.922C1078.64 383.707 190.947 857.183 -417.982 1182.86" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1556.11 157.395C1336.8 272.943 1138.62 373.018 1111.01 372.503C1100.61 372.261 196.1 873.053 -419.072 1214.45" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1494.67 171.24C1303.37 277.055 1145.68 361.31 1129.43 361.061C1122.48 360.899 201.004 889.37 -420.377 1246.22" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            <path d="M1447.67 181.09C1281.68 277.584 1155.17 349.681 1147.95 349.621C1144.42 349.538 205.965 906.012 -421.51 1278.34" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
                            </g>
                        </svg>  
                </div>
        </Box>
      <CustomTabPanel value={value} index={0}>
        <div className='data_contain_tabs'>
           
            <div className='p_tabs_'>
            <p>
                <b>
            Location:
                </b>
             Delhi/NCR <br />
             <b>
            Education:
             </b>
              Graduate / Post Graduate <br />
              <b>
            Years of Experience:
              </b>
             8 to 15 years <br />
             <b>

            Openings:
             </b>
             1 <br />
             <b>

            Salary:
             </b>
             As per the industry standards along with performance-based lucrative Incentive <br /><br />
            <b>
            Profile Summary:
            </b>
            Desired candidate will lead the development of the region allocated. The prime deliverable is to achieve business targets for the entire product with effective control and supervision of all Network relationships. He/She will work to improve the sales team’s productivity, network relations and services, and surpass AOP/goals. Build impactful strategies to drive volumes and build network relationships in the locations.

            <br />
            <br />
            <b>    
            Desired Skills:
            </b> 

            <ul>
                <li>8-15 years of experience in BFSI with at least relevant experience of 3-5 years in SME Business Loans </li>
                <li>Good knowledge of SME products as well as markets for, business loans in the geography. </li>
                <li>Have sound knowledge of local references, deep penetration of the local market and good rapport with the business segments in the geography.</li>
                <li>Analytical skills and understanding of financial statements. Flair for numbers and the ability to track the movement of numbers</li>
                <li>Strong track record during their career</li>
                <li>Good written and verbal communications</li>
                <li>Good knowledge of computers including MS Office and Google Drives.</li>
                <li>Should have managed at least 8 to 9 on-roll employees for minimum 5 years.</li>
                <li>Should have managed multiple branches/cities/states.</li>
            </ul>

            <br />
            <b>
                Behavioral Competencies:
            </b> 
            <ul>
                <li>
            Good interpersonal skills. Result-oriented with a positive outlook and ability to convince clients, seniors and subordinates across functions and be a solution provider. 
                </li>
                <li>
            Ability to analyze and think strategically.
                </li>
                <li>
            System and process orientation
                </li>
                <li>
            Quality orientation
                </li>
                <li>
            Problem-solving ability
                </li>
                <li>
            Customer Service Orientation
                </li>
                <li>
            Entrepreneurial and able to build a strong network
                </li>
                <li>
            Strong track record of leading teams to deliver stretch projects with high-impact
                </li>
                <li>
            Willing to travel across the region 
                </li>
            </ul>

            <b>
                Key responsibilities:
            </b>
            <ul>
                <li>
            Sourcing Unsecured Business Loan, Secured Loans, All types of Investments & Insurance in the market through Channel Partners / Reference.
                </li>
                <li>
            Lead a result-oriented team and is responsible for managing the Cluster profitability.
                </li>
                <li>
            Ensuring high-quality service and optimal Network Relationship Management
                </li>
                <li>
            Conduct periodic strategic reviews of the business portfolio across the locations allocated.
                </li>
                <li>
            Define and implement necessary strategies and processes to ensure a high level of performance.
                </li>
                <li>
            Collaborate with other functions (Operations, Marketing, Credit, etc.) to ensure the sales team gets the necessary support to develop and grow the business and achieve targets.
                </li>
                <li>
            Generate leads through various activities - Seminars, Deployments, local engagement etc
                </li>
                <li>
            Resolving customer queries to help.
                </li>
                <li>
            Maximize performance of sales team by identifying training needs and ensure delivery of training takes place.
                </li>
                <li>
            Maintaining punctuality, discipline and a high level of morality in tune with the ethics & values of Niyogin.
                </li>
                <li>
            Cost control.
                </li>
            </ul>

            <b>
            Job Responsibilities will also include
            </b>

            <ul>
                <li>
            Financial assessment of SME clients and sourcing to be in line with the credit policy/norms.
                </li>
                <li>
            Assess the customers based on customer financial documents, FI reports, Bureau reports (CIBIL, etc.), and Personal Discussions.
                </li>
                <li>
            Smartly figure out customer's credentials and perform tele-verification of the customer Should conduct risk analysis and mitigation of risk at your level
                </li>
                <li>
            Ensure timely disbursals after loan sanction 
                </li>
                <li>
            Periodic monitoring of customers and business and look for early warning signals 
                </li>
                <li>
            Monitor the performance of the portfolio based on Non-Starters & Early Delinquency across sourcing channels, products, customer segments, etc. 
                </li>
                <li>
            Resolve audit queries if any and to avoid the same in the future 
                </li>
                <li>
            Recommend changes in existing policies and suggest new requirements as per market requirements and opportunities.
                </li>
                <li>
            Ensure adherence to KYC / AML screening requirements of the organization 
                </li>
                <li>
            Ensure that there is no compromise on the Quality of Credit & maintain a healthy portfolio
                </li>
            </ul>

            </p>
            </div>
                
            <div  className='careear_btn_div'>
                <button  onClick={handleOpen}>APPLY NOW</button>
            </div>
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}  >
      <div className='data_contain_tabs'>
           
            <div className='p_tabs_'>
                <p>
                    <b>
                Title: 
                    </b>
                Sales Manager - Sales <br />
                <b>
                Education:
                </b>
                 Graduate / Post Graduate <br />
                 <b>
                Years of Experience:
                 </b>
                 5 - 10 years <br />
                <br />
                <b>
                Desired Skills:
                </b>
                    <ul>
                        <li>
                Graduate or Postgraduate - Any Specialization. Prefer 5 -10 years of experience in Channel/Partner Sales/Service.
                        </li>
                        <li>
                Should have strong Sales orientation.
                        </li>
                        <li>
                Leadership skills (Though more tactical than strategic)
                        </li>
                        <li>
                Cost efficiency through process
                        </li>
                        <li>
                An immense interest in developing relationships.
                        </li>
                        <li>
                Should have good communication & presentation skills.
                        </li>
                        <li>
                Should be a self-starter, proactive & target-oriented.
                        </li>
                        <li>
                Should possess strong networking & relationship building skills
                        </li>
                    </ul>

                <b>
                Key responsibilities:
                </b>

                <ul>
                    <li>
                Responsible for new Partner acquisition, building new relationships and increasing depth in existing relationships with Partners
                    </li>
                    <li>
                End-to-end partner relationship management.
                    </li>
                    <li>
                Revenue Generation & Management.
                    </li>
                    <li>
                Will work within the boundaries of our business model using the expertise of the product group and complying with the requirements and processes in place.
                    </li>
                    <li>
                Resolving escalated grievances and queries of partners and their customers on a day-to-day basis.
                    </li>
                    <li>
                Developing the satisfaction level of partners & customers by rendering services beyond excellence to optimize a high percentage of partner & customer satisfaction and retention.
                    </li>
                    <li>
                Responsible for achieving targets as assigned by the organization every month.
                    </li>
                    <li>
                Understands clients' risk profiles and maximizes the - risk vs. reward- balance for individual clients & Partners by optimizing product mix. Comply with KYC/SEBI/RBI rules, regulations, and legislation governing the financial services industry.
                    </li>
                </ul>

                </p>
            </div>
            <div className='careear_btn_div'>
                <button  onClick={handleOpen}>APPLY NOW</button>
            </div>
        
        </div>
      </CustomTabPanel>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='model_of_career'
      >
        <div className='model_cont_vcar'>
        <Box sx={style}>
            <h3 className='title_model_carrer'>Submit Application</h3>
          <CareerFormMui/>
        </Box>
        </div>
      </Modal>
    </Box>
        </div>
    </div>
  )
}
