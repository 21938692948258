import React from 'react'
import CareerBanner from '../components/CareerBanner'
import CareerVacancies from '../components/CareerVacancies'
import CareerTabs from '../components/CareerTabs'

export default function Career() {
  return (
    <div>
        <CareerBanner/>
        <CareerTabs/>
        <CareerVacancies/>
    </div>
  )
}
