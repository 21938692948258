import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import emailjs from "@emailjs/browser";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[500],
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '12px', 
    fontSize: '16px', 
  },
  '& .MuiInputLabel-root': {
    fontSize: '14px', 
  },
  '& .MuiFormHelperText-root': {
    fontSize: '12px',
  },
}));

const FormContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px',
  gap: '16px',
  maxWidth: '1250px',
  margin: '0 auto',
});

const FormButton = styled(Button)({
  width: '100%',
  backgroundColor: 'blue',
  color: 'white',
  '&:hover': {
    backgroundColor: 'darkblue',
  },
});

const FormField = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  width: '100%',
});

const FormInput = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
});

function FormMui() {
  // Initial state for the form data
  const initialState = {
    name: '',
    designation: '',
    company: '',
    phone: '',
    email: '',
    location: '',
  };

  // Set form state
  const [formData, setFormData] = useState(initialState);

  // Snackbar states
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Handle change in form input fields
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Get the form element
    const form = event.target;

    // Send the form data using EmailJS
    const result = await sendEmail(form);

    if (result.success) {
      // Clear the form data if email is sent successfully
      setFormData(initialState); // Reset form data to initial state
      // Show success Snackbar
      setSnackbarMessage('Email sent successfully!');
      setSnackbarSeverity('success');
    } else {
      // Show error Snackbar
      setSnackbarMessage('Failed to send email. Please try again.');
      setSnackbarSeverity('error');
    }

    // Open the Snackbar
    setOpenSnackbar(true);
  };

  // Function to send the form data using EmailJS
  async function sendEmail(formElement) {
    try {
      // Send the form data using emailjs
      const result = await emailjs.sendForm(
        'service_oy8vjkk',  // Your EmailJS service ID
        'template_52pzi51', // Your EmailJS template ID
        formElement,        // The form element
        '1u5_ja3OnLOr52lhv' // Your EmailJS user ID
      );
      console.log('Email sent successfully:', result.text);
      return { success: true };
    } catch (error) {
      console.error('Error sending email:', error);
      return { success: false };
    }
  }

  // Close Snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <FormContainer className='form_first'>
      <form onSubmit={handleSubmit} id='lb-form'>
        {/* Name Field */}
        <FormField>
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              fullWidth={true}
              value={formData.name}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>

        {/* Designation Field */}
        <FormField>
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="designation"
              name="designation"
              label="Designation"
              variant="outlined"
              fullWidth={true}
              value={formData.designation}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>

        {/* Company Field */}
        <FormField>
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="company"
              name="company"
              label="Company"
              variant="outlined"
              fullWidth={true}
              value={formData.company}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>

        {/* Phone Field */}
        <FormField className='phone_div'>
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="phone"
              name="phone"
              label="Phone"
              variant="outlined"
              fullWidth={true}
              value={formData.phone}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>

        {/* Email Field */}
        <FormField className='email_div'>
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              fullWidth={true}
              value={formData.email}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>

        {/* Location Field */}
        <FormField>
          <FormInput className='input_style_cl'>
            <StyledTextField
              id="location"
              name="location"
              label="Location"
              variant="outlined"
              fullWidth={true}
              value={formData.location}
              onChange={handleChange}
            />
          </FormInput>
        </FormField>

        {/* Submit Button */}
        <div className='btn_container'>
          <FormButton className='verify_btn' type="submit" variant="contained">
            Submit
          </FormButton>
        </div>
      </form>

      {/* Snackbar for showing the result of email submission */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </FormContainer>
  );
}

export default FormMui;
