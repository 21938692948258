import React from "react";
import privacyImage from "../assets/images/PrivacyPolicyImg.png";

const PrivacyPolicyComponent = () => {
  const Section = ({ title, description }) => (
    <div
      className="privacy-section"
      data-aos="fade-right"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1500"
    >
      <p className="privacy-section-title">{title}</p>
      <div
        className="privacy-section-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  );

  const privacyPolicyData = [
    {
      title: "",
      description: `
        <p>In the course of using this website or its custom extensions or those availing the products and services via the 
        online application forms and questionnaires (“User”), Niyogin Fintech Limited and its affiliates may become privy 
        to the personal information of its Users, including information that is of a confidential nature.</p>
        <p>Niyogin Fintech Limited is strongly committed to protecting the privacy of its Users and has taken all necessary 
        and reasonable measures to protect the confidentiality of the User information and its transmission through the 
        world wide web and it shall not be held liable for disclosure of the confidential information when in accordance 
        with this privacy commitment or in terms of the agreements, if any, with the Users.</p>
        <p>Niyogin Fintech Limited endeavours to safeguard and ensure the security of the information provided by the User. 
        The User would be required to cooperate with Niyogin Fintech Limited in order to ensure the security of the 
        information, and it is recommended that the Users necessarily choose their passwords carefully such that no 
        unauthorised access is made by a third party. To make the password complex and difficult for others to guess, the 
        Users should use combination of alphabets, numbers and special characters. The Users should undertake not to 
        disclose their password to anyone or keep any written or other record of the password such that a third party 
        could access it.</p>
        <p>Niyogin Fintech Limited undertakes not to disclose the information provided by the Users to any person, unless 
        such action is necessary to:</p>
        <ul>
          <li>Conform to legal requirements or comply with legal process.</li>
          <li>Protect and defend Niyogin Fintech Limited or its Affiliates' rights, interests or property.</li>
          <li>Enforce the terms and conditions of the products or services; or</li>
          <li>Act to protect the interests of Niyogin Fintech Limited, its Affiliates, or its members, constituents or of other persons.</li>
        </ul>
        <p>The Users shall not disclose to any other person, in any manner whatsoever, any information relating to Niyogin Fintech Limited or its Affiliates of a confidential nature obtained in the course of availing the services through the website. Failure to comply with this obligation shall be deemed a serious breach of the terms herein and shall entitle Niyogin Fintech Limited or its Affiliates to terminate the services, without prejudice to any damages, to which the User may be entitled otherwise.</p>
        <p>Niyogin Fintech Limited will limit the collection and use of User information only on a need-to- know basis to deliver better service to the Users.</p>
        <p>User may need to share SMS permission to send and view messages to ensure the SIM card in the phone & registered Phone number match. The send SMS permission is used for meeting the mandatorily required provision by Reserve Bank of India (RBI) rules for security purposes by Niyogin Fintech Limited as well as Unified Payment Interface (UPI) authentication.</p>
        <p>Niyogin Fintech Limited may use and share the information provided by the Users with its Affiliates and third parties for providing services and any service-related activities such as collecting subscription fees for such services and notifying or contacting the Users regarding any problem with, or the expiration of, such services. In this regard, it may be necessary to disclose the User information to one or more agents and contractors of Niyogin Fintech Limited and their sub-contractors, but such agents, contractors, and sub- contractors will be required to agree to use the information obtained from Niyogin Fintech Limited only for these purposes.</p>
        <ul>
        <li>Niyogin Fintech Limited will ensure data protection and privacy as required in relevant legislation, regulations, and, if applicable, contractual clauses for each outsourced activity.</li>
        <li>Niyogin Fintech Limited will ensure that data identified as Personally Identified Information (PII) and Sensitive private data or information (SPDI) are protected based on the policy.</li>
        <li>Niyogin Fintech Limited will ensure that PII/SPDI of an individual are collected only where there is relevant business/regulatory requirement and not shared with a third party without consent from information owner.</li>
        <li>Niyogin Fintech Limited will ensure that PII/SPDI collected are classified as confidential and protected in all form.</li>
        <li>Niyogin Fintech Limited will ensure that access to data identified as per this policy are monitored and reviewed.</li>
        <li>Niyogin Fintech Limited will provide appropriate trainings to its employees / suppliers to ensure proper handling of Niyogin's data.</li>
        <li>Niyogin Fintech Limited will ensure that incidents which involve compromise of personal and sensitive information are considered as privacy incidents and are managed appropriately.</li>
        </ul>
        `,
    },
    {
      title: "What types of personal data do Niyogin collect?",
      description: `
      <p>Niyogin Fintech Limited may collect personal data with respect to business and corporate client relationships is primarily limited to the information on directors and officers, other employees, direct and indirect beneficial owners and authorised persons we need to enable us to meet our due diligence obligations, signatory details and contact information of individuals we interact with to enable the provision of products and services to clients.</p>
      <p>If User gives us someone else’s personal data, User must have their permission and explain to them how Niyogin Fintech Limited will use such personal data as permitted under the applicable law.</p>
      <p>Niyogin Fintech Limited may collect the following types of personal data about User, as relevant and with User’s consent or where it is allowed by the applicable law. The types of personal data may change from time to time as required by the applicable law:</p>
      <ul>
        <li><strong> Identification data </strong> – information that identifies (uniquely or semi uniquely) User. For example, name, date of birth, gender, login credentials, photographs, other and identifiers, including official/government identifiers such as tax identification number, PAN number and Aadhaar number.</li>
        <li><strong>Contact data </strong> – information that allows addressing, sending or communicating a message to User. For example, email address, phone or mobile number and residential or business address.</li>
        <li><strong>Professional data</strong> – information about educational or professional background.</li>
        <li><strong>Geo-location data</strong> – information that provides or contains a device’s location. For example, internet protocol (IP) address or cookies identifier.</li>
        <li><strong>Behavioural data </strong> – analytics information that describes behavioural characteristics relating to use of Niyogin’s products and services. For example, usual transactional activities, browsing behaviour on the website and how User interact for our products and services, or those provided by third-party organisations, such as Niyogin’s advertising partners and social media platform providers.</li>
        <li><strong>Personal relationship data</strong> – information about associations or close connections between individuals or entities that can determine User’s identity. For example, spouse or employer relationships.</li>
        <li><strong>Communications data </strong> – information relating to User contained in voice, messaging, email, live chats and other communications we have with User. For example, service requests.</li>
      </ul>

      <p>We may sometimes need to collect more sensitive personal data or information about User, but we only do this if it’s necessary and with User consent, or where it is allowed by applicable law. This sensitive personal data (sometimes known as special category personal data in other jurisdictions) may include things like:</p>
      <ul>
        <li><strong>Financial and commercial data</strong> – User account and transaction information or information that identifies User’s financial position and background, status and history as necessary to provide relevant products and services. For example, account details, debit or credit card details, source of funds, interest rate, average bank balance of the last 90 days, credit counts in the last 30 day and 60 days, last loan amount, new to credit/non new to credit, renewal, financial and credit rating history and any payment instrument details.</li>
        <li><strong>Criminal convictions, proceedings or allegations data</strong> – information about criminal convictions or related information that Niyogin identify in relation to our financial crime prevention obligations, for example, details about any criminal convictions or related information. This includes details of offences or alleged offences or convictions.</li>
      </ul>
      `,
    },
    {
      title: "Information Sharing",
      description: `
      <p>Niyogin Fintech Limited will not sell or rent User Information to anyone, for any reason, at any time. However, we will be sharing User Information with our financial partners, affiliates and business partners, and the User hereby consents to the same. We will take reasonable steps to ensure that these third-party service providers are obligated to protect the information and are also subject to appropriate confidentiality/non-disclosure obligations and they comply with the applicable provisions of the data protection laws.</p>
      `,
    },
    {
      title: "Log & Record Retention Policy",
      description: `
      <p>In accordance with applicable laws and internal policies, Niyogin Fintech Limited may retain User information provided for as long as required to provide services to the User such as managing User’s account and dealing with any concerns that may arise, or if required for compliance with any legal or regulatory requirements, or for the institution, enforcement, or defense of legal claims.</p>
      <p>Niyogin Fintech Limited may also retain User information for business and related purposes, including but not limited to, responding to queries or complaints, combating with fraud and financial crime or in accordance with contractual obligations.</p>
      <p>If the retention of User information is not required, we have deployed policies and procedures to destroy or delete such User information on a best effort basis. Niyogin Fintech Limited is committed to safeguarding the User privacy and security. We want to assure that we do not store any facial data.</p>
      `,
    },
    {
      title: "Note",
      description: `
      <ul>
        <li>Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</li>
        <li>By visiting this website, you agree to be bound by the terms and conditions of this privacy policy.</li>
        <li>By mere use of the website, you expressly consent to our use and disclosure of your personal information in accordance with this privacy policy. This privacy policy is incorporated into and subject to the terms of use.</li>
        <li>You may withdraw your consent at any time by providing a written notice to Niyogin. Upon receipt of your written request, we will cease the use and disclosure of your personal information in accordance with this privacy policy.</li>
      </ul>
      `,
    },
  ];

  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-header">
        <img
          src={privacyImage}
          alt="Privacy Policy"
          className="privacy-policy-image"
          data-aos="fade-right"
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        />
        <p
          className="privacy-policy-title"
          data-aos="fade-left"
          data-aos-offset="10"
          data-aos-easing="ease-in-sine"
          data-aos-duration="1000"
        >
          PRIVACY
          <br />
          POLICY
        </p>
      </div>
      <div className="privacy-policy-content">
        {privacyPolicyData.map((section, index) => (
          <Section
            key={index}
            title={section.title}
            description={section.description}
          />
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
