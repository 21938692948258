import React from 'react'

export default function InvestorLightBlue() {
  return (
    <div className='InvestorLightBlue_main_section'>
        <p>
        Investor Grievance Redressal Officer: Ms. Trivenika Avasthi Investor Relations Officer
        Tel No: 022-6251 4646   Email ID: investorrelations@niyogin.in
        </p>
        <p>
        Nodal Officer/Customer Grievance Redressal Officer/Nodal Officer to deal with FinTech/ 
        digital lending related complaints/ issues <br /><br />
        Mr. Noorallah Charania <br />
        Neelkanth Corporate IT Park, 311/312, 3rd Floor Kirol Road, Vidyavihar (W), Mumbai - 400086
        Tel No: 022 62514646   Email ID: noorallah.charania@niyogin.in
        </p>
    </div>
  )
}
