import React from "react";
import PhoneIcon from "../assets/images/phone-icon.png";
import EmailIcon from "../assets/images/email-icon.png";

const ContactInfo = () => {
  return (
    <div
      className="contact-info-container"
      data-aos="fade-up"
      data-aos-offset="10"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1000"
    >
      <p className="contact-title">REACH US AT</p>
      <div className="contact-details">
        <div className="contact-item">
          <a href="tel:+18002660266" className="contact-link">
            <img src={PhoneIcon} alt="Phone" className="contact-icon" />
            <p className="contact-text">1800-266-0266</p>
          </a>
        </div>
        <div className="contact-item">
          <a href="mailto:info@niyogin.in" className="contact-link">
            <img src={EmailIcon} alt="Email" className="contact-icon" />
            <p className="contact-text">info@niyogin.in</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
