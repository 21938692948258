import React from 'react'
import TermsAndConditions from '../components/TermsAndConditions'

const TermsConditions = () => {
  return (
    <div>
      <TermsAndConditions/>
    </div>
  )
}

export default TermsConditions
