import React from 'react'
import banner_career from "../assets/images/career_banner_img.png"

export default function CareerBanner() {
  return (
    <div className='CareerBanner_main_container'>
        <div className='CareerBanner_image'  data-aos="fade-up" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000" >
            <img src={banner_career} alt="" />
        </div>
        <div className='CareerBanner_text' data-aos="fade-down" data-aos-offset="10"
                    data-aos-easing="ease-in-sine" data-aos-duration="1000" >
            <p>
            <span>
            BECOME A PART OF A 
            STELLAR TEAM 
            </span>&nbsp;
            WITH TOP
            QUALITY TALENT
            </p>
        </div>
    </div>
  )
}
