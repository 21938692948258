import React from "react";
import ContactImage from "../assets/images/ContactUs_Image.png";

const ContactUsImage = () => {
  return (
    <div
      className="contact-us-container"
      data-aos="fade-up"
      data-aos-offset="10"
      data-aos-easing="ease-in-sine"
      data-aos-duration="1500"
    >
      <img src={ContactImage} alt="Contact Us" className="contact-us-image" />
    </div>
  );
};

export default ContactUsImage;
