import React, { useEffect, useRef, useState, useCallback } from "react";
import anime from "animejs/lib/anime.es.js";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import nextIcon from "../assets/images/nextIcon.png";
import prevIcon from "../assets/images/prevIcon.png";

import leftArrow from "../assets/images/left-arrow.svg";
import rightArrow from "../assets/images/right-arrow.svg";
import { DEVELOPMENT_WORDPRESS_DOMAIN } from "../utils/domain";

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-prev" onClick={onClick}>
      <img src={leftArrow} alt="Prev" />
    </div>
  );
};

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-next" onClick={onClick}>
      <img src={rightArrow} alt="Next" />
    </div>
  );
};

export { PrevArrow, NextArrow };

export default function FintechNews() {
  const svgRef_Fintech1 = useRef(null);
  const fintechNewsRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const newsPerPage = 9;

  const fetchNews = useCallback(async (page) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${DEVELOPMENT_WORDPRESS_DOMAIN}/wp-json/wp/v2/media_releases?page=${page}&per_page=${newsPerPage}`
      );
      
      if (!response.ok) {
        if (response.status === 404 && page > 1) {
          setNewsData([]);
          setLoading(false);
          return;
        }
        throw new Error("Network response was not ok");
      }
      
      const text = await response.text();
      
      if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
        const data = JSON.parse(text);
        setNewsData(data);
        console.log("Media Data", data);
      } else {
        const cleanedText = text.replace(/<[^>]*>/g, "");  
        const jsonStartIndex = cleanedText.indexOf('[');
        const jsonString = cleanedText.slice(jsonStartIndex).trim();
        const data = JSON.parse(jsonString);
        setNewsData(data);
      }
      
      const totalPagesHeader = response.headers.get('X-WP-TotalPages');
      setTotalPages(totalPagesHeader ? parseInt(totalPagesHeader) : 1);
      
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
      console.error("Error fetching news:", error);
    }
  }, [newsPerPage]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.disconnect();
          }
        });
      },
      { threshold: 0.5 }
    );

    if (svgRef_Fintech1.current) {
      observer.observe(svgRef_Fintech1.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const svg3 = svgRef_Fintech1.current;
    if (svg3 && isInView) {
      const paths3 = svg3.querySelectorAll("path");
      anime({
        targets: paths3,
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "easeInOutSine",
        duration: 5000,
        delay: 0,
      });
    }
  }, [isInView]);

  useEffect(() => {
    fetchNews(currentPage);
    
    if (fintechNewsRef.current) {
      window.scrollTo({ 
        top: fintechNewsRef.current.offsetTop - 100, 
        behavior: 'smooth' 
      });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentPage, fetchNews]);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages && !loading) {
      setCurrentPage(pageNumber);
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const getImageUrl = (newsItem) => {
    if (
      newsItem?.yoast_head_json?.og_image && 
      Array.isArray(newsItem.yoast_head_json.og_image) && 
      newsItem.yoast_head_json.og_image.length > 0 && 
      newsItem.yoast_head_json.og_image[0]?.url
    ) {
      return newsItem.yoast_head_json.og_image[0].url;
    }
    return "https://via.placeholder.com/300x200?text=Image+Not+Found";
  };

  const handleImageError = (e) => {
    e.target.src = "https://via.placeholder.com/300x200?text=Image+Not+Found";
    e.target.classList.add("placeholder-image");
  };

  return (
    <div className="fintech-news-wrapper" ref={fintechNewsRef}>
      <div
        className="fintech-header"
        data-aos="fade-up"
        data-aos-easing="ease-in-sine"
        data-aos-duration="1500"
      >
        <p className="fintech-title">NIYOGIN IN THE NEWS</p>
        <p>
          We've been spoken about in the news from time to time, and it's with
          great pleasure that we want to share this coverage with you.
        </p>
      </div>

      <div className="svg_div_fintechNews">
        <svg
          width="100%"
          ref={svgRef_Fintech1}
          id="my_svg_element"
          height="100%"
          viewBox="0 0 1425 885"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="drawsvg-initialized"
        >
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="1425"
            height="885"
          >
            <rect width="1425" height="885" fill="#C4C4C4"></rect>
          </mask>
          {/* SVG content here */}
        </svg>
      </div>

      {loading ? (
        <div className="loader"></div>
      ) : error ? (
        <p>Error: {error.message}</p>
      ) : newsData.length === 0 ? (
        <div className="no-news">
          <p>No news articles available for this page.</p>
        </div>
      ) : isMobile ? (
        <Slider {...sliderSettings} className="fintech-slider">
          {newsData.map((news, index) => (
            <div 
              className="fintech-card-slider" 
              key={index} 
              onClick={() => window.open(news.acf?.read_more || "#")}
            >
              <div className="image-container">
                <img 
                  src={getImageUrl(news)} 
                  alt={news.title?.rendered ? `News article image: ${news.title.rendered}` : "Image not found"}
                  onError={handleImageError}
                />
              </div>
              <div className="card-content">
                <div className="card-meta">
                  <span className="card-date">{news.acf?.date || "No date"}</span>
                  <span className="card-source">{news.acf?.source || "No source"}</span>
                </div>
                <p dangerouslySetInnerHTML={{ __html: news.title?.rendered || "No title" }}></p>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="fintech-cards-container">
          {newsData.map((news, index) => (
            <div 
              className="fintech-card" 
              key={index} 
              onClick={() => window.open(news.acf?.read_more || "#")}
            >
              <div className="image-container">
                <img 
                  src={getImageUrl(news)} 
                  alt={news.title?.rendered ? `News article image: ${news.title.rendered}` : "Image not found"}
                  onError={handleImageError}
                />
              </div>
              <div className="card-content">
                <div className="card-meta">
                  <span className="card-date">{news.acf?.date || "No date"}</span>
                  <span className="card-source">{news.acf?.source || "No source"}</span>
                </div>
                <p dangerouslySetInnerHTML={{ __html: news.title?.rendered || "No title" }}></p>
              </div>
            </div>
          ))}
        </div>
      )}
      {totalPages > 1 && (
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="prev-page"
            disabled={currentPage === 1 || loading}
          >
            <img src={prevIcon} alt="Previous" />
          </button>
          <span className="current-page">{currentPage}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="next-page"
            disabled={currentPage === totalPages || loading}
          >
            <img src={nextIcon} alt="Next" />
          </button>
        </div>
      )}
    </div>
  );
}